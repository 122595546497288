import React, { useState, useEffect } from 'react';
import { Card, Button, Container, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import conferenceService from '../services/conferenceService';
import sessionService from '../services/sessionService';
import speakerService from '../services/speakerService';
import tokenService from '../services/tokenService';
import authService from '../services/authService';

 // Adjust the import path as needed

const QuickConferencePanel: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const currentUser = authService.getCurrentUser();
    setUser(currentUser);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await authService.getCurrentUser();
        setUser(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Kullanıcı bilgileri alınamadı.');
      }
    };
    fetchUserData();
  }, []);

  const navigate = useNavigate();

  const handleQuickConference = async () => {
    setIsLoading(true);
    setError(null);

    const today = new Date();
    const conferenceName = `Konferans ${today.toLocaleDateString('tr-TR')}`;
    const speakerName = user ? user.name : `Konuşmacı ${today.toLocaleDateString('tr-TR')}`;
    const formattedDate = today.toISOString().split('T')[0];

    try {
      // 1. Konferans oluştur
      const conferenceResponse = await conferenceService.createNewConference({
        name: conferenceName,
        date: formattedDate,
        location: 'Online'
      });
      
      console.log('Conference created:', conferenceResponse);
      
      const conferenceId = conferenceResponse.id;
  
      // 2. Oturum oluştur
      const sessionResponse = await sessionService.createSession({
        conference_id: conferenceId,
        name: `${conferenceName} - Oturum`,
        description: 'Hızlı oluşturulan konferans oturumu',
        auto_approve_questions: true
      });
  
      console.log('Full session response:', sessionResponse);
      
      // sessionResponse'un yapısını kontrol et ve sessionId'yi bul
      let sessionId;
      if (sessionResponse && sessionResponse.session && sessionResponse.session.id) {
        sessionId = sessionResponse.session.id;
      } else if (sessionResponse && sessionResponse.id) {
        sessionId = sessionResponse.id;
      } else {
        throw new Error('Session ID bulunamadı');
      }

      console.log('Session ID:', sessionId);

      if (!sessionId) {
        throw new Error('Geçerli bir Session ID alınamadı');
      }

      // 3. Konuşmacı oluştur
      const speakerResponse = await speakerService.createSpeaker({
        session_id: sessionId,
        name: speakerName,
        email: user && user.email ? user.email : `speaker_${today.getTime()}@example.com`,
        role: 'Konuşmacı'
      });
      
        
      console.log('Speaker created:', speakerResponse);
  
      const speakerId = speakerResponse.data.speaker.id;

      // 4. Token oluştur
      const tokenResponse = await tokenService.generateToken(sessionId, speakerId, 'Konuşmacı');
      console.log('Token generated:', tokenResponse);
  
      if (!tokenResponse || !tokenResponse.token) {
        throw new Error('Token oluşturulamadı');
      }

      // 5. Konuşmacı paneline yönlendir
      navigate(`/speaker/${tokenResponse.token}`);
  
    } catch (err) {
      console.error('Hızlı konferans oluşturma hatası:', err);
      if (err.response) {
        setError(`Sunucu hatası: ${err.response.data.message || err.response.statusText}`);
      } else if (err.request) {
        setError('Sunucuya ulaşılamadı. Lütfen internet bağlantınızı kontrol edin.');
      } else {
        setError(`Bir hata oluştu: ${err.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="admin-panel conference-card">
            <Card.Header as="h2" className="text-center">Hızlı Etkinlik Oluştur</Card.Header>
            <Card.Body>
              {error && <Alert variant="danger">{error}</Alert>}
              <p className="mb-3">
                Hızlı etkinlik oluşturma özelliği ile anında bir etkinlik başlatabilirsiniz. 
                Bu seçenek:
              </p>
              <ul className="mb-4">
                <li>Otomatik olarak bir etkinlik ve oturum oluşturur</li>
                <li>Sizi konuşmacı olarak atar</li>
                <li>Dersi, semineri, konferansı ya da sunumu hemen başlatmanıza olanak tanır</li>
                <li>Minimum ayarla maksimum verim sağlar</li>
              </ul>
              <p className="mb-4">
                Etkinliğinizi daha sonra özelleştirebilir ve detaylı ayarlar yapabilirsiniz.
              </p>
              <Button 
                variant="primary" 
                onClick={handleQuickConference} 
                disabled={isLoading}
                className="w-100"
              >
                {isLoading ? (
                  <>
                    <Spinner animation="border" size="sm" className="me-2" />
                    Konferans Oluşturuluyor...
                  </>
                ) : (
                  'Hızlı Etkinlik Oluştur ve Başlat'
                )}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default QuickConferencePanel;
import axios from 'axios';

const api = axios.create({
 //baseURL: 'http://localhost:3000/api',
 baseURL: 'https://interedu.app/api',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

api.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
    console.log('Sending token in request:', token); // Debugging için
  } else {
    console.log('No token found in sessionStorage'); // Debugging için
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  console.error('API error:', error.response); // Debugging için
  return Promise.reject(error);
});

export default api;
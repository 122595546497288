import api from './api.js';

const conferenceService = {
  
  createNewConference: async (conferenceData) => {
    try {
      console.log('Creating new conference:', conferenceData);
      const response = await api.post('/conferences', conferenceData);
      console.log('Conference creation response:', response.data);
      return response.data; // Doğrudan response.data'yı döndürüyoruz
    } catch (error) {
      console.error('Error creating conference:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  getAllConferences: () => {
    console.log('Fetching all conferences for the user');
    return api.get('/conferences');
  },

  updateConference: async (id, conferenceData) => {
    try {
      console.log('Updating conference:', id, conferenceData);
      const response = await api.put(`/conferences/${id}`, conferenceData);
      console.log('Conference update response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating conference:', error.response || error);
      throw error;
    }
  },

  getConference: (id) => {
    return api.get(`/conferences/${id}`);
  },


  deleteConference: (id) => {
    return api.delete(`/conferences/${id}`);
  },
  getAllConferencesWithSessionCount: async () => {
    try {
      console.log('Sending request to get all conferences with session count');
      const response = await api.get(`/conferences/all`);
      console.log('Received response:', response);
      return response;
    } catch (error) {
      console.error('Error in getAllConferencesWithSessionCount:', error);
      throw error;
    }
  },
  getUserConferences: async () => {
    console.log('Fetching user conferences');
    try {
      const response = await api.get('/conferences/user');
      console.log('User conferences response:', response.data);
      return response;
    } catch (error) {
      console.error('Error fetching user conferences:', error);
      throw error;
    }
  },
  
};

export default conferenceService;
import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import QRCode from 'qrcode.react';
import { Container, Row, Col } from 'react-bootstrap';
import { ReactComponent as Logo } from '../assets/logo.svg';

export const QRCodeDisplay = ({ sessionLink }) => {
  const [qrSize, setQrSize] = useState(300);

  useEffect(() => {
    const handleResize = () => {
      const size = Math.min(window.innerWidth * 0.8, window.innerHeight * 0.5);
      setQrSize(Math.max(size, 200)); // Minimum 200px, maksimum ekranın %80'i
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // İlk yükleme için çağır

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Container fluid className="min-vh-100 d-flex flex-column justify-content-between bg-white py-4">
      <Row className="justify-content-center align-items-center flex-grow-1">
        <Col xs={12} md={8} lg={6} className="text-center">
          <Logo className="mb-4" style={{ maxWidth: '200px', height: 'auto' }} />
          
          <h2 className="mb-4">Soru Sormak İçin QR Kodunu Okutun</h2>
          
          <div className="d-flex justify-content-center mb-4">
            <QRCode value={sessionLink} size={qrSize} level="H" />
          </div>
          
          <p className="lead mb-2">QR kodu tarayarak veya aşağıdaki bağlantıyı kullanarak katılabilirsiniz:</p>
          <p className="text-break">{sessionLink}</p>
        </Col>
      </Row>
      
      <footer className="text-center text-muted mt-4">
        <p>© 2024 interEdu.app | Etkinlik QR Kodu</p>
      </footer>
    </Container>
  );
};

export const openQRCodeInNewWindow = (sessionLink) => {
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  const qrCodeWindow = window.open('', 'QRCode', `width=${screenWidth},height=${screenHeight},top=0,left=0`);
  qrCodeWindow.document.write(`
    <!DOCTYPE html>
    <html>
      <head>
        <title>Konferans QR Kodu</title>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
          integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM"
          crossorigin="anonymous"
        />
        <style>
          body, html {
            height: 100%;
            margin: 0;
            overflow: hidden;
          }
        </style>
      </head>
      <body>
        <div id="qr-root" style="height: 100%;"></div>
      </body>
    </html>
  `);
  qrCodeWindow.document.close();

  qrCodeWindow.onload = () => {
    try {
      qrCodeWindow.document.documentElement.requestFullscreen();
    } catch (e) {
      console.log('Tam ekran modu otomatik olarak başlatılamadı:', e);
    }
  };

  const root = createRoot(qrCodeWindow.document.getElementById('qr-root'));
  root.render(<QRCodeDisplay sessionLink={sessionLink} />);
};

export default QRCodeDisplay;
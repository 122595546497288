import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import './styles/login.css';
import { FaGoogle } from "react-icons/fa";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import authService from '../services/authService';
import jwtDecode from 'jwt-decode';
import logo from '../assets/logo_short.svg'; // logo_short.svg dosyasının yolunu doğru şekilde belirtin
import { GOOGLE_CLIENT_ID } from './moderator/utils/goglcli'; // Bu satırı ekleyin
interface LoginComponentProps {
  onLogin: (userData: any) => void;
}

const Logo: React.FC = () => (
  <img src={logo} alt="Logo" className="L1logo" />
);

const LoginComponent: React.FC<LoginComponentProps> = ({ onLogin }) => {
  const [isActive, setIsActive] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get('tab');
    if (tab === 'register') {
      setIsActive(true);
    }
  }, [location]);

  const toggleForm = () => {
    setIsActive(!isActive);
    setIsForgotPassword(false);
    setError('');
    // Form alanlarını temizle
    setEmail('');
    setPassword('');
    setName('');
  };

  const showForgotPassword = () => {
    setIsActive(false);
    setIsForgotPassword(true);
    setError('');
  };

  const backToLogin = () => {
    setIsForgotPassword(false);
    setIsActive(false);
    setError('');
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    
    if (!email || !password) {
      setError('Email and password are required');
      return;
    }
    
    try {
      const result = await authService.login(email, password, rememberMe);
      console.log('Login successful, result:', result);
      if (result.token && result.user) {
        onLogin(result);
      } else {
        setError('Login successful but user data is incomplete');
      }
    } catch (error) {
      console.error('Login error:', error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unexpected error occurred');
      }
    }
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    
    if (!name || !email || !password) {
      setError('All fields are required');
      return;
    }
    
    try {
      await authService.register(name, email, password);
      setError('Registration successful. Please log in.');
      setIsActive(false);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unexpected error occurred');
      }
    }
  };

  const handleForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await authService.forgotPassword(email);
      alert(`Şifre sıfırlama bağlantısı ${email} adresine gönderildi. Lütfen e-postanızı kontrol edin.`);
      setIsForgotPassword(false);
    } catch (error) {
      setError('Şifre sıfırlama işlemi başarısız oldu. Lütfen e-posta adresinizi kontrol edin.');
    }
  };

  const handleGoogleLogin = async (credentialResponse: any) => {
    try {
      const result = await authService.googleLogin(credentialResponse.credential);
      onLogin(result);
    } catch (error) {
      console.error('Google login failed', error);
      setError('Google ile giriş başarısız oldu.');
    }
  };

  const handleGoogleRegister = async (credentialResponse: any) => {
    try {
      const result = await authService.googleRegister(credentialResponse.credential);
      onLogin(result); // Kayıt başarılı olursa kullanıcıyı direkt giriş yaptırıyoruz
    } catch (error) {
      console.error('Google register failed', error);
      setError('Google ile kayıt başarısız oldu.');
    }
  };

  const handleRememberMe = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  }, []);

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <section className="L1section">
        <div className={`L1container ${isActive ? 'L1active' : ''} ${isForgotPassword ? 'L1forgotPassword' : ''}`}>
          <div className="L1user L1signinBox">
            <div className="L1imgBox L1imgBox3">
              <Logo />
            </div>
            <div className="L1formBox">
              <form onSubmit={handleLogin}>
                <h2>GİRİŞ YAP</h2>
                <GoogleLogin
                  onSuccess={handleGoogleLogin}
                  onError={() => {
                    console.log('Login Failed');
                    setError('Google ile giriş başarısız oldu.');
                  }}
                />
                <div className="L1sign-in-separator">veya E-posta ile Giriş Yap</div>
                <input type="email" placeholder="E-posta" required value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type="password" placeholder="Şifre" required value={password} onChange={(e) => setPassword(e.target.value)} />
                <div className="L1remember-forgot">
                  <label className="L1remember-me">
                    <input 
                      type="checkbox" 
                      checked={rememberMe}
                      onChange={handleRememberMe}
                    /> 
                    Beni hatırla
                  </label>
                  <a href="#" className="L1forgot-password" onClick={showForgotPassword}>
                    Şifrenizi mi unuttunuz?
                  </a>
                </div>
                <button className="L1rounded-button" type="submit">Giriş Yap</button>
                {error && <p className="error-message">{error}</p>}
                <p className="L1signup">
                  Hesabınız yok mu? <a href="#" onClick={(e) => { e.preventDefault(); toggleForm(); }}>Kayıt Ol.</a>
                </p>
              </form>
            </div>
          </div>

          <div className="L1user L1signupBox">
            <div className="L1formBox">
              <form onSubmit={handleRegister}>
                <h2>Hesap Oluştur</h2>
                <GoogleLogin
                  onSuccess={handleGoogleLogin}
                  onError={() => {
                    console.log('Register Failed');
                    setError('Google ile kayıt başarısız oldu.');
                  }}
                />
                <div className="L1sign-in-separator">veya E-posta ile Kayıt Ol</div>
                <input type="text" placeholder="Ad Soyad" required value={name} onChange={(e) => setName(e.target.value)} />
                <input type="email" placeholder="E-posta" required value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type="password" placeholder="Şifre" required value={password} onChange={(e) => setPassword(e.target.value)} />
                <button className="L1rounded-button" type="submit">Kayıt Ol</button>
                {error && <p className="error-message">{error}</p>}
                <p className="L1signup">
                  ZATEN BİR HESABINIZ VAR MI? <a href="#" onClick={(e) => { e.preventDefault(); toggleForm(); }}>GİRİŞ YAP.</a>
                </p>
              </form>
            </div>
            <div className="L1imgBox L1imgBox1">
              <Logo />
            </div>
          </div>
          <div className="L1user L1forgotPasswordBox">
            <div className="L1formBox">
              <form onSubmit={handleForgotPassword}>
                <h2>ŞİFREMİ UNUTTUM</h2>
                <p style={{color:"#555"}}>E-posta adresinizi girin ve size şifrenizi sıfırlamanız için bir bağlantı göndereceğiz.</p>
                <input type="email" name="email" placeholder="E-posta" required value={email} onChange={(e) => setEmail(e.target.value)} />
                <button className="L1rounded-button" type="submit">Sıfırlama Bağantısı Gönder</button>
                {error && <p className="error-message">{error}</p>}
                <p className="L1signup">
                 ŞİFRENİZİ HATIRLADINIZ MI? <a href="#" onClick={backToLogin}>GİRİŞ'E DÖN</a>
                </p>
              </form>
            </div>
            <div className="L1imgBox L1imgBox2">
              <Logo />
            </div>
          </div>
        </div>
      </section>
    </GoogleOAuthProvider>
  );
};

export default LoginComponent;
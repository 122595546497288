import api from './api.js';
import { jwtDecode } from 'jwt-decode';

const authService = {
  register: async (name, email, password) => {
    try {
      const response = await api.post('/users/register', { name, email, password });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message || 'Registration failed');
      } else if (error.request) {
        throw new Error('No response from server. Please check your internet connection.');
      } else {
        throw new Error('An error occurred. Please try again later.');
      }
    }
  },
  
  login: async (email, password, rememberMe) => {
    try {
      console.log('Sending login request:', { email, password, rememberMe });
      const response = await api.post('/users/login', { email, password, rememberMe });
      console.log('Login response:', response.data);
      if (response.data.token) {
        const userData = {
          id: response.data.id,
          name: response.data.name,
          email: response.data.email
        };
        const storage = rememberMe ? localStorage : sessionStorage;
        storage.setItem('token', response.data.token);
        storage.setItem('user', JSON.stringify(userData));
        api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        console.log('Token and user saved:', response.data.token, userData);
        return { token: response.data.token, user: userData };
      } else {
        console.error('Login response does not contain token');
        throw new Error('Incomplete login data received from server');
      }
    } catch (error) {
      console.error('Login error:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    delete api.defaults.headers.common['Authorization'];
  },

  getCurrentUser: () => {
    const userDataString = localStorage.getItem('user') || sessionStorage.getItem('user');
    if (!userDataString) return null;
    try {
      return JSON.parse(userDataString);
    } catch (error) {
      console.error('Error parsing user data:', error);
      return null;
    }
  },
  
  setAuthHeader: () => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      console.log('No token found in storage');
    }
  },

  getProfileInfo: async () => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'));

      console.log('Token from storage:', token); // Debugging için
      console.log('User from storage:', user); // Debugging için

      if (!token || !user) {
        throw new Error('No token or user found');
      }

      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      const response = await api.get(`/users/profile/${user.id}`);
      return response.data;
    } catch (error) {
      console.error('Error in getProfileInfo:', error.response?.data || error.message);
      throw error;
    }
  },

  updateProfile: async (profileData) => {
    try {
      const user = JSON.parse(sessionStorage.getItem('user') || '{}');
      const response = await api.put(`/users/profile/${user.id}`, profileData);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Profil güncellenirken bir hata oluştu');
      }
    } catch (error) {
      throw error;
    }
  },

  forgotPassword: async (email) => {
    try {
      const response = await api.post('/users/forgot-password', { email });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  changePassword: async (currentPassword, newPassword) => {
    try {
      const user = authService.getCurrentUser();
      if (!user || !user.id) {
        throw new Error('User information not found');
      }

      const response = await api.put(`/users/${user.id}/change-password`, { currentPassword, newPassword });
      console.log('Password change response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error changing password:', error.response?.data || error.message);
      throw error;
    }
  },

  googleLogin: async (credential) => {
    try {
      const response = await api.post('/auth/google', { token: credential });
      if (response.data.token) {
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('user', JSON.stringify(response.data.user));
        api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        console.log('Google login token saved:', response.data.token);
      }
      return response.data;
    } catch (error) {
      console.error('Google login error:', error);
      throw error;
    }
  },

  googleRegister: async (credential) => {
    try {
      const response = await api.post('/auth/google-register', { credential });
      if (response.data.token && response.data.user) {
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('user', JSON.stringify(response.data.user));
        api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        return response.data;
      } else {
        throw new Error('Google ile kayıt başarısız oldu');
      }
    } catch (error) {
      console.error('Google kayıt hatası:', error);
      throw error;
    }
  },

  checkAuthStatus: () => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'));
    console.log('Current auth status - Token:', token, 'User:', user);
    return { token, user };
  }
};

export default authService;
import { useState, useEffect } from 'react';
import speakerService from '../../../services/speakerService';
import socketService from '../../../services/socketService';

const useFetchSpeakers = (sessionId) => {
  const [speakers, setSpeakers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSpeakers = async () => {
      if (!sessionId) return;
      
      setIsLoading(true);
      try {
        const response = await speakerService.getSpeakersBySession(sessionId);
        const fetchedSpeakers = response.data;

        if (Array.isArray(fetchedSpeakers)) {
          setSpeakers(fetchedSpeakers);
        } else {
          throw new Error('Konuşmacı verileri beklenen formatta değil.');
        }
      } catch (err) {
        console.error('Konuşmacıları yüklerken bir hata oluştu:', err);
        setError('Konuşmacıları yüklerken bir hata oluştu. Lütfen tekrar deneyin.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSpeakers();

    // Socket.IO event listeners
    socketService.on('newSpeaker', handleNewSpeaker);
    socketService.on('updateSpeaker', handleUpdateSpeaker);
    socketService.on('deleteSpeaker', handleDeleteSpeaker);

    return () => {
      socketService.off('newSpeaker', handleNewSpeaker);
      socketService.off('updateSpeaker', handleUpdateSpeaker);
      socketService.off('deleteSpeaker', handleDeleteSpeaker);
    };
  }, [sessionId]);

  const handleNewSpeaker = (newSpeaker) => {
    setSpeakers(prevSpeakers => [...prevSpeakers, newSpeaker]);
  };

  const handleUpdateSpeaker = (updatedSpeaker) => {
    setSpeakers(prevSpeakers => 
      prevSpeakers.map(s => s.id === updatedSpeaker.id ? updatedSpeaker : s)
    );
  };

  const handleDeleteSpeaker = (deletedSpeakerId) => {
    setSpeakers(prevSpeakers => prevSpeakers.filter(s => s.id !== deletedSpeakerId));
  };

  return { speakers, setSpeakers, isLoading, error };
};

export default useFetchSpeakers;
import React from 'react';
import styled from 'styled-components';

const FooterSection = styled.footer`
  background-color: #1e293b;
  color: white;
  padding: 3rem 0;
  text-align: center;
`;

const Footer: React.FC = () => {
  return (
    <FooterSection>
      <div className="container">
        <p>&copy; 2024 interEdu. Tüm hakları saklıdır.</p>
      </div>
    </FooterSection>
  );
};

export default Footer;
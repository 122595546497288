import React from 'react';
import styled from 'styled-components';

const HowItWorksSection = styled.section`
  padding: 6rem 0;
  background-color: #f1f5f9;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 4rem;
  font-weight: 700;
  color: #1e293b;
`;

const ImageCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2.5rem;
`;

const ImageCard = styled.div`
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 20rem;
  object-fit: cover;
  transition: transform 0.3s ease;
  ${ImageCard}:hover & {
    transform: scale(1.05);
  }
`;

const CardContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  color: white;
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
`;

const HowItWorks: React.FC = () => {
  const steps = [
    {
      title: "Eğitim Oluşturun",
      description: "Yönetim merkezini kullanarak konferansınızı, sunumunuzu ya da eğitim etkinliğinizi ve oturumlarınızı oluşturun.",
      imageUrl: "https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
    },
    {
      title: "Katılımcıları Davet Edin",
      description: "Size özel oluşturulan QR kodları veya oturum linklerini katılımıclarla paylaşın.",
      imageUrl: "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1351&q=80"
    },
    {
      title: "Etkileşimi Başlatın",
      description: "Katılımcılar soru sorsun, moderatörler yönetsin, konuşmacılar cevaplasın.",
      imageUrl: "https://images.unsplash.com/photo-1505373877841-8d25f7d46678?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
    }
  ];

  return (
    <HowItWorksSection id="how-it-works">
      <div className="container">
        <SectionTitle>Nasıl Çalışır?</SectionTitle>
        <ImageCardGrid>
          {steps.map((step, index) => (
            <ImageCard key={index}>
              <CardImage src={step.imageUrl} alt={step.title} />
              <CardContent>
                <CardTitle>{step.title}</CardTitle>
                <p>{step.description}</p>
              </CardContent>
            </ImageCard>
          ))}
        </ImageCardGrid>
      </div>
    </HowItWorksSection>
  );
};

export default HowItWorks;
// Ana renkler ve tonları
export const COLORS = {
    PRIMARY: {
      MAIN: '#3498db',
      LIGHT: '#5dade2',
      DARK: '#2980b9',
      LIGHTER: '#85c1e9',
      DARKER: '#1f618d',
    },
    SECONDARY: {
      MAIN: '#2ecc71',
      LIGHT: '#58d68d',
      DARK: '#27ae60',
      LIGHTER: '#82e0aa',
      DARKER: '#1d8348',
    },
    ACCENT: {
      MAIN: '#e74c3c',
      LIGHT: '#ec7063',
      DARK: '#c0392b',
      LIGHTER: '#f1948a',
      DARKER: '#922b21',
    },
    NEUTRAL: {
      WHITE: '#ffffff',
      BLACK: '#000000',
      GRAY: {
        100: '#f7f7f7',
        200: '#e1e1e1',
        300: '#cfcfcf',
        400: '#b1b1b1',
        500: '#9e9e9e',
        600: '#7e7e7e',
        700: '#626262',
        800: '#515151',
        900: '#3b3b3b',
      },
    },
  };
  
  // Durum renkleri
  export const STATUS = {
    SUCCESS: {
      MAIN: '#27ae60',
      LIGHT: '#2ecc71',
      DARK: '#219653',
    },
    WARNING: {
      MAIN: '#f39c12',
      LIGHT: '#f1c40f',
      DARK: '#d35400',
    },
    ERROR: {
      MAIN: '#c0392b',
      LIGHT: '#e74c3c',
      DARK: '#922b21',
    },
    INFO: {
      MAIN: '#2980b9',
      LIGHT: '#3498db',
      DARK: '#1f618d',
    },
  };
  
  // Semantik renkler
  export const SEMANTIC = {
    LINK: '#3498db',
    VISIT: '#8e44ad',
    BORDER: '#bdc3c7',
    DISABLED: '#95a5a6',
  };
  
  // Marka renkleri
  export const BRAND = {
    FACEBOOK: '#1877f2',
    TWITTER: '#1da1f2',
    LINKEDIN: '#0077b5',
    INSTAGRAM: '#e4405f',
  };
  
  // Arka plan renkleri
  export const BACKGROUND = {
    DEFAULT: '#f5f5f5',
    PAPER: '#ffffff',
    DARK: '#2c3e50',
  };
  
  // Metin renkleri
  export const TEXT = {
    PRIMARY: '#333333',
    SECONDARY: '#7f8c8d',
    DISABLED: '#bdc3c7',
    INVERSE: '#ffffff',
  };
  
  // Gölgelendirme
  export const SHADOW = {
    LIGHT: 'rgba(0, 0, 0, 0.1)',
    MEDIUM: 'rgba(0, 0, 0, 0.2)',
    DARK: 'rgba(0, 0, 0, 0.3)',
  };
  
  // Gradyanlar
  export const GRADIENTS = {
    PRIMARY: `linear-gradient(135deg, ${COLORS.PRIMARY.MAIN}, ${COLORS.PRIMARY.DARK})`,
    SECONDARY: `linear-gradient(135deg, ${COLORS.SECONDARY.MAIN}, ${COLORS.SECONDARY.DARK})`,
    ACCENT: `linear-gradient(135deg, ${COLORS.ACCENT.MAIN}, ${COLORS.ACCENT.DARK})`,
    SUNSET: 'linear-gradient(to right, #ff7e5f, #feb47b)',
    OCEAN: 'linear-gradient(to left, #4facfe 0%, #00f2fe 100%)',
    FOREST: 'linear-gradient(to right, #134e5e 0%, #71b280 100%)',
  };
  
  // Buton renkleri
  export const BUTTON = {
    PRIMARY: {
      BACKGROUND: COLORS.PRIMARY.MAIN,
      TEXT: TEXT.INVERSE,
      HOVER: COLORS.PRIMARY.DARK,
      ACTIVE: COLORS.PRIMARY.DARKER,
    },
    SECONDARY: {
      BACKGROUND: COLORS.SECONDARY.MAIN,
      TEXT: TEXT.INVERSE,
      HOVER: COLORS.SECONDARY.DARK,
      ACTIVE: COLORS.SECONDARY.DARKER,
    },
    ACCENT: {
      BACKGROUND: COLORS.ACCENT.MAIN,
      TEXT: TEXT.INVERSE,
      HOVER: COLORS.ACCENT.DARK,
      ACTIVE: COLORS.ACCENT.DARKER,
    },
    OUTLINE: {
      BORDER: COLORS.PRIMARY.MAIN,
      TEXT: COLORS.PRIMARY.MAIN,
      HOVER: {
        BACKGROUND: COLORS.PRIMARY.LIGHT,
        TEXT: TEXT.INVERSE,
      },
      ACTIVE: {
        BACKGROUND: COLORS.PRIMARY.MAIN,
        TEXT: TEXT.INVERSE,
      },
    },
  };
  
  // Tema renkleri (koyu mod için)
  export const DARK_THEME = {
    BACKGROUND: BACKGROUND.DARK,
    TEXT: TEXT.INVERSE,
    PRIMARY: COLORS.PRIMARY.LIGHT,
    SECONDARY: COLORS.SECONDARY.LIGHT,
  };
  
  // Erişilebilirlik renkleri
  export const ACCESSIBILITY = {
    FOCUS_RING: '#4a90e2',
    HIGH_CONTRAST: {
      BACKGROUND: '#000000',
      TEXT: '#ffffff',
      LINK: '#ffff00',
    },
  };
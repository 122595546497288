export const mapStatus = (status) => {
  const statusMap = {
    'Pending': 'Beklemede',
    'Approved': 'Onaylandı',
    'Rejected': 'Reddedildi',
    'Answered': 'Cevaplandı'
  };
  return statusMap[status] || status;
};

export const reverseMapStatus = (status) => {
  const reverseStatusMap = {
    'Beklemede': 'Pending',
    'Onaylandı': 'Approved',
    'Reddedildi': 'Rejected',
    'Cevaplandı': 'Answered'
  };
  return reverseStatusMap[status] || status;
};
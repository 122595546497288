import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Spinner,
  Form,
  InputGroup,
} from "react-bootstrap";
import { FaTrash, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "./ConfirmationModal";
import conferenceService from "../services/conferenceService";

interface Conference {
  id: string;
  name: string;
  date: string;
  isDateRange: boolean;
  endDate?: string;
  location: string;
  sessionCount: number;
}

const ConferenceList: React.FC = () => {
  const [conferences, setConferences] = useState<Conference[]>([]);
  const [filteredConferences, setFilteredConferences] = useState<Conference[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedConference, setSelectedConference] =
    useState<Conference | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    loadConferences();
  }, []);

  const loadConferences = async () => {
    setIsLoading(true);
    try {
      const response = await conferenceService.getUserConferences();
      console.log("Fetched conferences:", response.data);
      setConferences(response.data);
    } catch (error) {
      console.error("Error loading conferences:", error);
      setError(
        "Konferansları yüklerken bir hata oluştu. Lütfen daha sonra tekrar deneyin."
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    filterConferences();
  }, [searchTerm, conferences]);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      const cards = document.querySelectorAll(".custom-conference-card");
      cards.forEach((card) => {
        const rect = card.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const glow = card.querySelector(".custom-glow") as HTMLElement;
        if (glow) {
          glow.style.background = `radial-gradient(circle at ${x}px ${y}px, rgba(255,255,255,0.2), transparent 60%)`;
          glow.style.opacity = "0.8";
          glow.style.transition = "background 0.3s ease, opacity 0.3s ease"; // Yumuşak geçişler için transition ekledim
        }
      });
    };

    const handleMouseLeave = () => {
      const glows = document.querySelectorAll(".custom-glow");
      glows.forEach((glow) => {
        const glowElement = glow as HTMLElement;
        glowElement.style.opacity = "0";
        glowElement.style.transition = "opacity 0.5s ease"; // Opaklığın yavaş yavaş kaybolması için geçiş süresi ekledim
      });
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  const filterConferences = () => {
    const filtered = conferences.filter(
      (conference) =>
        conference.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        conference.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
        new Date(conference.date).toLocaleDateString().includes(searchTerm)
    );
    setFilteredConferences(filtered);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleEdit = (conference: Conference) => {
    navigate(`/admin/${conference.id}`);
  };

  const handleDelete = (conference: Conference) => {
    setSelectedConference(conference);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (selectedConference) {
      try {
        await conferenceService.deleteConference(selectedConference.id);
        setConferences(
          conferences.filter((conf) => conf.id !== selectedConference.id)
        );
        setShowModal(false);
      } catch (error) {
        console.error("Error deleting conference:", error);
      }
    }
  };

  const getConferenceDetails = (conference: Conference) => {
    const { date, isDateRange, endDate, location } = conference;
    const details = [];
    if (date) {
      if (isDateRange && endDate) {
        details.push(
          `${new Date(date).toLocaleDateString()} - ${new Date(
            endDate
          ).toLocaleDateString()}`
        );
      } else {
        details.push(new Date(date).toLocaleDateString());
      }
    }
    if (location) {
      details.push(location);
    }
    return details.join(" | ");
  };

  if (isLoading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <>
      <style>
        {`
          .custom-conference-list-container {
            // background: linear-gradient(135deg, #2b4255, #0E2C5E);
            min-height: 300vh;
            padding: 10px;
          }

          .custom-conference-card {
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 15px;
            overflow: hidden;
            backdrop-filter: blur(10px);
            border: 1px solid rgba(255, 255, 255, 0.2);
            position: relative;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            display: flex;
            height: 250px;
            cursor: pointer;
            min-width: 200px;
            margin-right: 10px;
          
          }

          .custom-conference-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
          }

          .custom-card-header {
            background-color: rgba(255, 255, 255, 0.1);
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
          
          }

          .custom-card-title {
            color: white;
            font-size: 1.4rem;
            font-weight: 600;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }

          .custom-card-body {
            padding: 30px;
            padding-left: 10px;
            padding-top: 10px;
            padding-right: 10px;
            width: 60%;
            display: flex;
            flex-direction: column;
           
             font-size: 1.1rem;
             justify-content: center;
          
           
          }

          .custom-card-text {
            color: rgba(255, 255, 255, 0.8);
            margin-bottom: 10px;
            padding-top: 25px;
          }

          .custom-card-footer {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            opacity: 0;
            transition: opacity 0.3s ease;
            position: absolute;
            bottom: 20px;
            right: 25px;
          }

          .custom-conference-card:hover .custom-card-footer {
            opacity: 1;
          }

          .custom-session-count {
            color: #ff;
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(10px);
            padding: 5px 10px;
            border-radius: 15px;
            font-size: 0.9rem;
            font-weight: 600;
            transition: transform 0.3s ease;
          }

          .custom-glow {
            position: absolute;
            inset: 0;
            background: radial-gradient(circle at 50% 50%, rgba(255,255,255,0.2), transparent 50%);
            opacity: 0;
            transition: opacity 0.3s ease;
            pointer-events: none;
          }

          .custom-search-container {
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 15px;
            padding: 20px;
            margin-bottom: 20px;
            backdrop-filter: blur(10px);
            margin-right: 10px;
          }

          .custom-search-input {
            background-color: rgba(255, 255, 255, 0.2);
            border: none;
            color: white;
          }

          .custom-search-input::placeholder {
            color: rgba(255, 255, 255, 0.6);
          }

          .custom-search-input:focus {
            background-color: rgba(255, 255, 255, 0.3);
            box-shadow: none;
            color: white;
          }

          .custom-search-icon {
            background-color: rgba(255, 255, 255, 0.3);
            color: white;
            border: none;
          }
        `}
      </style>
      <div className="custom-conference-list-container">
        <Container fluid>
          <div className="custom-search-container">
            <InputGroup>
              <InputGroup.Text className="custom-search-icon">
                <FaSearch />
              </InputGroup.Text>
              <Form.Control
                className="custom-search-input"
                placeholder="Konferans adı, tarih veya konum ara..."
                aria-label="Konferans ara"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </InputGroup>
          </div>
          {filteredConferences.length === 0 ? (
            <p className="text-center text-white">Konferans bulunamadı.</p>
          ) : (
            <Row xs={1} sm={1} md={2} xl={3} className="g-4">
              {filteredConferences.map((conference) => (
                <Col key={conference.id}>
                  <div
                    className="custom-conference-card"
                    onClick={() => handleEdit(conference)}
                  >
                    <div className="custom-glow"></div>
                    <div className="custom-card-header">
                      <h3 className="custom-card-title">{conference.name}</h3>
                    </div>
                    <div className="custom-card-body">
                      <div>
                        <span className="custom-session-count">
                          {conference.sessionCount} Oturum
                        </span>
                        <p className="custom-card-text">
                          {getConferenceDetails(conference)}
                        </p>
                      </div>
                      <div className="custom-card-footer">
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(conference);
                          }}
                        >
                          <FaTrash /> Sil
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </Container>
        <ConfirmationModal
          show={showModal}
          onHide={() => setShowModal(false)}
          onConfirm={confirmDelete}
          title="Etkinlik Sil"
          body="Bu etkinliği silmek istediğinize emin misiniz? Bu işlem geri alınamaz."
        />
      </div>
    </>
  );
};

export default ConferenceList;

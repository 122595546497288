import React from 'react';
import ReactDOM from 'react-dom/client';

const generateMultipleBoxShadow = (n) => {
  let value = `${Math.floor(Math.random() * 2000)}px ${Math.floor(Math.random() * 2000)}px #FFF`;
  for (let i = 2; i <= n; i++) {
    value += `, ${Math.floor(Math.random() * 2000)}px ${Math.floor(Math.random() * 2000)}px #FFF`;
  }
  return value;
};

const styles = {
  body: {
    height: '100vh',
    width: '100vw',
    margin: 0,
    padding: 0,
    background: 'radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%)',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: "'Open Sans', sans-serif",
  },
  starsContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  stars: {
    width: '1px',
    height: '1px',
    background: 'transparent',
    boxShadow: generateMultipleBoxShadow(700),
    animation: 'animStar 50s linear infinite',
  },
  stars2: {
    width: '2px',
    height: '2px',
    background: 'transparent',
    boxShadow: generateMultipleBoxShadow(200),
    animation: 'animStar 100s linear infinite',
  },
  stars3: {
    width: '3px',
    height: '3px',
    background: 'transparent',
    boxShadow: generateMultipleBoxShadow(100),
    animation: 'animStar 150s linear infinite',
  },
  glassCard: {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '10px',
    padding: '30px',
    backdropFilter: 'blur(2px)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    //maxWidth: '80%',
    width: '800px',
    zIndex: 2,

  },
  question: {
    fontSize: '30px',
    marginBottom: '20px',
    color: '#FFF',
    textShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
    lineHeight: '1.5',
    textAlign: 'center',
  },
  asker: {
    fontSize: '12px',
    opacity: 0.8,
    color: '#FFF',
    textAlign: 'right',
  fontStyle: 'italic',
  },
};

const keyframes = `
  @keyframes animStar {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-2000px);
    }
  }
`;

export const SingleQuestionDisplay = ({ question }) => {
  return (
    <div style={styles.body}>
      <style>{keyframes}</style>
      <div style={styles.starsContainer}>
        <div style={styles.stars}></div>
        <div style={styles.stars2}></div>
        <div style={styles.stars3}></div>
      </div>
      <div style={styles.glassCard}>
        <div style={styles.question}>{question.text}</div>
        <div style={styles.asker}>{question.asker}</div>
      </div>
    </div>
  );
};

export const openQuestionInNewWindow = (question) => {
  const questionWindow = window.open('', 'Question', 'width=800,height=600');
  questionWindow.document.write(`
    <html>
      <head>
        <title>Soru Detayı</title>
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap&subset=latin-ext" rel="stylesheet">
        <style>
          body, html { margin: 0; padding: 0; width: 100%; height: 100%; }
        </style>
      </head>
      <body>
        <div id="question-root"></div>
      </body>
    </html>
  `);
  questionWindow.document.close();

  const root = ReactDOM.createRoot(questionWindow.document.getElementById('question-root'));
  root.render(<SingleQuestionDisplay question={question} />);
};
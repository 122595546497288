import api from './api';
import { mapStatus, reverseMapStatus } from './statusMapping';

const speakerService = {
  getAllSpeakers: () => api.get('/speakers'),
  
  getSpeakerById: (id) => api.get(`/speakers/${id}`),
  
  createSpeaker: async (speakerData) => {
    console.log('Sending speaker data to server:', speakerData);
    try {
      const response = await api.post('/speakers', speakerData);
      console.log('Server response:', response);
      return response;
    } catch (error) {
      console.error('Error in createSpeaker:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        throw new Error(`Server error: ${error.response.data.message || error.response.statusText}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        throw new Error('No response received from server');
      } else {
        console.error('Error setting up request:', error.message);
        throw error;
      }
    }
  },
  updateSpeaker: async (id, speakerData) => {
    try {
      const response = await api.put(`/speakers/${id}`, speakerData);
      console.log('Server response for updateSpeaker:', response);
      return response.data;
    } catch (error) {
      console.error('Error in updateSpeaker:', error);
      throw error;
    }
  },
  
  deleteSpeaker: (id) => api.delete(`/speakers/${id}`),

  getQuestionsForSpeaker: async (sessionId, speakerId) => {
    try {
      const response = await api.get(`/questions/session/${sessionId}/speaker/${speakerId}`);
      return response.data.map(question => ({
        ...question,
        text: question.text || question.question_text || '',
        status: mapStatus(question.status) // statusMapping.js'den import edilmeli
      }));
    } catch (error) {
      console.error('Error fetching questions for speaker:', error);
      throw error;
    }
  },
  
  getSpeakersBySession: (sessionId) => api.get(`/speakers/session/${sessionId}`),
  // speakerService.js
  getSpeakerInfo: async (sessionId) => {
    try {
      console.log('Fetching speaker info for session:', sessionId);
      const response = await api.get(`/speakers/info/${sessionId}`);
      console.log('Speaker info:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching speaker info:', error);
      throw error;
    }
  },

  getSpeakerInfoById: async (speakerId) => {
    try {
      console.log('Fetching speaker info for ID:', speakerId);
      const response = await api.get(`/speakers/${speakerId}`);
      console.log('Speaker info:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching speaker info:', error);
      throw error;
    }
  },

};

export default speakerService;
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
interface HeroWrapperProps {
  backgroundImage: string;
}

const HeroWrapper = styled.section<HeroWrapperProps>`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

const HeroBlurWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeroBlur = styled.div<HeroWrapperProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 120%;
  height: 120%;
  background: ${props => `url(${props.backgroundImage}) no-repeat center center`};
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  filter: blur(15px) brightness(60%);
  z-index: 1;
  transform: translate(-10%, -10%);
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 5rem;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.25rem;
  margin-bottom: 2rem;
`;

const HeroButton = styled.a`


   background-color: #4A90E2;
color: white;
    padding: 0.75rem 2rem;
    border-radius: 9999px;
    text-decoration: none;
    font-weight: 600;
    display: inline-block;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
   &:hover {
  background-color: #357ABD;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

// background-color: #4A90E2;
//   color: white;
//   font-weight: bold;
//   padding: 0.75rem 1.5rem;
//   border-radius: 9999px;
//   text-decoration: none;
//   transition: background-color 0.3s ease;
//   font-size: 1.125rem;

//   &:hover {
//     background-color: #357ABD;
//   }
`;


  


interface HeroProps {
  title: string;
  subtitle: string;
  buttonText: string;
  buttonLink: string;
  backgroundImage: string;
  onButtonClick?: () => void;
}

const Hero: React.FC<HeroProps> = ({
  title,
  subtitle,
  buttonText,
  buttonLink,
  backgroundImage,
  onButtonClick
}) => {
    const navigate = useNavigate();
  return (
    <HeroWrapper backgroundImage={backgroundImage} id="home">
      <HeroBlurWrapper>
        <HeroBlur backgroundImage={backgroundImage} />
        <HeroContent className="container mx-auto animate__animated animate__fadeIn">
          <HeroTitle>{title}</HeroTitle>
          <HeroSubtitle>{subtitle}</HeroSubtitle>
          <HeroButton 
            href={buttonLink} 
            onClick={() => navigate('/login')}>
    
            {buttonText} <ArrowRight size={16} style={{marginLeft: '0.5rem', verticalAlign: 'middle'}} />
      
          </HeroButton>
        </HeroContent>
      </HeroBlurWrapper>
    </HeroWrapper>
  );
};

export default Hero;
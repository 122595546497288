import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Components/styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { GoogleOAuthProvider } from '@react-oauth/google';


// Bileşen importları
import Sidebar from './Components/Sidebar.js';
import ModeratorPanel from './Components/moderatorpanel.tsx';
import ParticipantPanel from './Components/participantPanel.tsx';
import AdminPanel from './Components/admin-panel.tsx';
import SpeakerPanel from './Components/speaker-panel.tsx';
import MainPage from './Components/MainPage.tsx';
import LoginPage from './Components/LoginPage.tsx';
import UserProfileComponent from './Components/Profile.tsx';
import Header from './Components/main/Header.tsx';
import ConferenceList from './Components/ConferenceList.tsx';
import QuickConferencePanel from './Components/QuickConferencePanel.tsx';
import SessionRedirect from './Components/SessionRedirect';
import Hero from './Components/main/Hero.tsx';
import Features from './Components/main/Features.tsx';
import HowItWorks from './Components/main/HowItWorks.tsx';
import Contact from './Components/main/Contact.tsx';
import Footer from './Components/main/Footer.tsx';
import authService from './services/authService';
import Dashboard from './Components/Dashboard.tsx';
import { BackgroundSelector, backgrounds } from './Components/styles/backgrounds';

// Ana sayfa bileşeni
const HomePage = () => (
  <>
    {/* <Header /> */}
    <Hero
      title="İnteraktif Etkinlik Yönetim Sistemi"
      subtitle="Konferanslar, Sunumlar, Dersler ve Toplantılar için İnteraktif Yönetim Çözümü!"
      buttonText="Hemen Başlayın"
      buttonLink="/login"
      backgroundImage={`${process.env.PUBLIC_URL}/b1.jpg`}
      onButtonClick={() => console.log('Üye ol butonuna tıklandı')}
    />
    {/* <Features />
    <HowItWorks />
    <Contact />
    <Footer /> */}
  </>
);

// PrivateRoute bileşeni
const PrivateRoute = ({ children }) => {
  const isLoggedIn = sessionStorage.getItem('token') !== null;
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

// AppContent bileşeni
const AppContent = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [conferences, setConferences] = useState([]);
  const [currentConference, setCurrentConference] = useState(null);
  const [error, setError] = useState(null);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [currentBackground, setCurrentBackground] = useState(() => {
    // localStorage'dan kayıtlı arka planı al, yoksa varsayılan olarak ilk arka planı kullan
    const savedBackground = localStorage.getItem('currentBackground');
    return savedBackground ? JSON.parse(savedBackground) : backgrounds[0];
  });

  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const userDataString = sessionStorage.getItem('user');
    let userData = null;
    
    try {
      userData = userDataString ? JSON.parse(userDataString) : null;
    } catch (error) {
      console.error('Error parsing user data:', error);
    }

    if (token && userData) {
      setIsLoggedIn(true);
      setUser(userData);
      authService.setAuthHeader();
    } else {
      setIsLoggedIn(false);
      setUser(null);
    }

    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      setIsMobileView(isMobile);
      setIsSidebarExpanded(false);

      // Mobil cihazlar için ek ayarlamalar
      if (isMobile) {
        document.body.style.minHeight = '100vh';
        document.body.style.minHeight = '-webkit-fill-available';
      } else {
        document.body.style.minHeight = '';
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = (expand) => {
    if (isMobileView) {
      setIsSidebarExpanded(!isSidebarExpanded);
    } else {
      setIsSidebarExpanded(expand !== undefined ? expand : !isSidebarExpanded);
    }
  };

  const handleLogin = (userData) => {
    console.log('Handling login in App.js:', userData);
    if (userData.token && userData.user) {
      setIsLoggedIn(true);
      setUser(userData.user);
      sessionStorage.setItem('token', userData.token);
      sessionStorage.setItem('user', JSON.stringify(userData.user));
      authService.setAuthHeader();
      navigate('/admin');
    } else {
      console.error('Invalid user data received:', userData);
      setError('Login failed: Invalid user data');
    }
  };

  const handleLogout = () => {
    authService.logout(); // authService'deki logout fonksiyonunu çağırın
    setIsLoggedIn(false);
    setUser(null);
    navigate('/');
  };

  const showSidebar = isLoggedIn && location.pathname !== '/';
  return (
    <div className={`app-container`} style={{ 
      background: currentBackground.value,
      minHeight: isMobileView ? '-webkit-fill-available' : '100vh'
    }}>
      <style>
        {`
          .app-container {
            display: flex;
            min-height: 100vh;
            transition: background 0.3s ease;
          }
          .sidebar {
         position: fixed;
            flex: 0 0 auto;
            width: ${showSidebar ? (isSidebarExpanded ? '250px' : '60px') : '0'};
            transition: width 0.3s ease;
            z-index: 1000;
          }
          .main-content {
            flex: 1;
            transition: margin-left 0.3s ease;
            width: 100%;
            margin-left: ${isLoggedIn ? '60px' : '0'};
          }
  
          @media (max-width: 768px) {
            .sidebar {
              position: fixed;
              top: 0;
              left: 0;
              bottom: 0;
              z-index: 1000;
              width: ${isSidebarExpanded ? '250px' : '0'};
            }
            .main-content {
              margin-left: 0 !important;
              width: 100% !important;
            }
          }
        `}
      </style>
      {showSidebar && (
        <div className="sidebar">
          <Sidebar
            isExpanded={isSidebarExpanded}
            toggleSidebar={toggleSidebar}
            handleLogout={handleLogout}
            user={user}
            isMobileView={isMobileView}
          />
        </div>
      )}
      <div className="main-content">
        {isMobileView && showSidebar && (
          <button
            onClick={() => toggleSidebar()}
            style={{
              position: 'fixed',
              top: '10px',
              left: '10px',
              zIndex: 1001,
              background: 'none',
              border: 'none',
              color: '#fff',
              fontSize: '24px',
              cursor: 'pointer',
            }}
          >
            {isSidebarExpanded ? <FaTimes /> : <FaBars />}
          </button>
        )}
        {!isLoggedIn && (
          // <nav className="navbar navbar-expand-lg navbar-light bg-light">
          //   <div className="container-fluid">
          //     <div style={{ width: '24px', height: '24px', backgroundColor: '#000', marginRight: '10px' }} />
          //     <Link className="navbar-brand" to="/">İnterEdu</Link>
          //     <button
          //       className="navbar-toggler"
          //       type="button"
          //       data-bs-toggle="collapse"
          //       data-bs-target="#navbarNav"
          //       aria-expanded="false"
          //       aria-label="Toggle navigation"
          //     >
          //       <span className="navbar-toggler-icon"></span>
          //     </button>
          //     <div className="collapse navbar-collapse" id="navbarNav">
          //       <ul className="navbar-nav me-auto">
          //         <li className="nav-item">
          //           <Link className="nav-link" to="/">Ana Sayfa</Link>
          //         </li>
          //         <li className="nav-item">
          //           <Link className="nav-link" to="/login">Giriş Yap</Link>
          //         </li>
          //       </ul>
          //     </div>
          //   </div>
          // </nav>
          <Header />
        )}
        <div className="container-fluid p-0">
       
          {error && <div className="alert alert-danger">{error}</div>}
          <Routes>
            <Route path="/" element={isLoggedIn ? <Navigate to="/admin" /> : <HomePage />} />
            <Route path="/login" element={isLoggedIn ? <Navigate to="/admin" /> : <LoginPage onLogin={handleLogin} />} />
            <Route path="/admin" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/admin/:id" element={<PrivateRoute><AdminPanel /></PrivateRoute>} />
            <Route path="/participant" element={<PrivateRoute><ParticipantPanel /></PrivateRoute>} />
            <Route path="/s/:token" element={<ParticipantPanel />} />
            <Route path="/moderator" element={<PrivateRoute><ModeratorPanel /></PrivateRoute>} />
            <Route path="/speaker" element={<PrivateRoute><SpeakerPanel /></PrivateRoute>} />
            <Route path="/profile" element={<PrivateRoute><UserProfileComponent /></PrivateRoute>} />
            <Route path="/quickConference" element={<PrivateRoute><QuickConferencePanel /></PrivateRoute>} />
            <Route path="/conferenceList" element={
              <PrivateRoute>
                <ConferenceList
                  conferences={conferences}
                  onEdit={(conference) => console.log('Edit conference:', conference)}
                  onDelete={(conferenceId) => console.log('Delete conference:', conferenceId)}
                />
              </PrivateRoute>
            } />
            <Route path="/newConference" element={
              <PrivateRoute>
                <AdminPanel
                  initialConference={currentConference}
                  onUpdateConference={setCurrentConference}
                />
              </PrivateRoute>
            } />
            <Route path="/session/:token" element={<SessionRedirect />} />
            <Route path="/moderator/:token" element={<ModeratorPanel />} />
            <Route path="/speaker/:token" element={<SpeakerPanel />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        
        </div>
      </div>
      <BackgroundSelector
        currentBackground={currentBackground}
        setCurrentBackground={setCurrentBackground}
      />
    </div>
  );
};

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <AppContent />
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Button,
  Form,
  InputGroup,
  Container,
  Row,
  Col,
  Spinner,
  Alert,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
import {
  FaCheckSquare,
  FaTimesCircle,
  FaEdit,
  FaEye,
  FaMicrophone,
  FaHandPaper,
  FaDownload,
  FaQrcode,
  FaCopy,
} from "react-icons/fa";
import QRCode from "qrcode.react";
import questionService from "../services/questionService";
import socketService from "../services/socketService";
import speakerService from "../services/speakerService";
import tokenService from "../services/tokenService";
import { openQuestionInNewWindow } from "./moderator/utils/SingleQuestionDisplay";
import QuestionCard from "./moderator/components/QuestionCardSpeaker.tsx";
import EditQuestionModal from "./moderator/components/EditQuestionModal.tsx";
import QuestionTable from "./moderator/components/QuestionTable.tsx";
import { exportToExcel } from "./moderator/utils/exportToExcel";
import UpdatedQRModal from "./UpdatedQRModal.js";
import "./styles/moderator.css";
import sessionService from "../services/sessionService";

interface Question {
  id: number;
  text: string;
  asker: string;
  status: string;
  wantsToAsk: boolean;
  onlyTakeFloor: boolean;
  speakers: Array<{ id: string; name: string }>;
}

const SpeakerPanel: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAnsweredFilter, setIsAnsweredFilter] = useState<string>("all");
  const [editingQuestion, setEditingQuestion] = useState<Question | null>(null);
  const [viewMode, setViewMode] = useState("card");
  const [speakerInfo, setSpeakerInfo] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [showQrModal, setShowQrModal] = useState(false);
  const [participantToken, setParticipantToken] = useState<string | null>(null);
  const [sessionName, setSessionName] = useState<string>("");
  const [copied, setCopied] = useState(false);
  const [sessionId, setSessionId] = useState<string>(""); // sessionId state'ini tanımla
  const [socket, setSocket] = useState<any>(null);

  const handleNewQuestion = useCallback((newQuestion: Question) => {
    console.log('Yeni soru alındı:', newQuestion);
    setQuestions(prevQuestions => {
      const updatedQuestions = [...prevQuestions, newQuestion];
      console.log('Güncellenmiş sorular:', updatedQuestions);
      return updatedQuestions;
    });
  }, []);

  const handleUpdateQuestion = useCallback((updatedQuestion: Question) => {
    console.log('Soru güncellendi:', updatedQuestion);
    setQuestions(prevQuestions => {
      const updatedQuestions = prevQuestions.map(q => 
        q.id === updatedQuestion.id ? updatedQuestion : q
      );
      console.log('Güncellenmiş sorular:', updatedQuestions);
      return updatedQuestions;
    });
  }, []);

  const handleDeleteQuestion = useCallback((deletedQuestionId: number) => {
    console.log('Soru silindi:', deletedQuestionId);
    setQuestions(prevQuestions => {
      const updatedQuestions = prevQuestions.filter(q => q.id !== deletedQuestionId);
      console.log('Güncellenmiş sorular:', updatedQuestions);
      return updatedQuestions;
    });
  }, []);

  useEffect(() => {
    let socket: any;

    const fetchQuestionsAndConnectSocket = async () => {
      try {
        const tokenInfo = await tokenService.validateToken(token);
        if (!tokenInfo.valid || tokenInfo.role !== "speaker") {
          setError("Geçersiz veya yetkisiz token.");
          return;
        }

        const speakerData = await speakerService.getSpeakerInfoById(
          tokenInfo.userId
        );
        setSpeakerInfo(speakerData);

        const fetchedQuestions = await questionService.getQuestionsForSpeaker(
          tokenInfo.sessionId,
          tokenInfo.userId
        );
        setQuestions(fetchedQuestions);

        const pToken = await tokenService.getParticipantToken(
          tokenInfo.sessionId
        );
        setParticipantToken(pToken);

        const sessionData = await sessionService.getSessionById(
          tokenInfo.sessionId
        );
        setSessionName(sessionData.name);
        setSessionId(sessionData.id); // sessionId state'ini güncelle

        if (tokenInfo.sessionId) {
          socket = socketService.connect(tokenInfo.sessionId);

          socket.on('connect', () => {
            console.log('Socket bağlantısı başarılı');
          });

          socket.on('newQuestion', handleNewQuestion);
          socket.on('updateQuestion', handleUpdateQuestion);
          socket.on('deleteQuestion', handleDeleteQuestion);

          console.log('Socket event listeners set up');
        }
      } catch (error) {
        console.error("Bilgi alma hatası:", error);
        setError("Oturum bilgileri yüklenirken bir hata oluştu.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuestionsAndConnectSocket();

    return () => {
      if (socket) {
        console.log('Cleaning up socket listeners');
        socket.off('newQuestion', handleNewQuestion);
        socket.off('updateQuestion', handleUpdateQuestion);
        socket.off('deleteQuestion', handleDeleteQuestion);
        socketService.disconnect();
      }
    };
  }, [token, handleNewQuestion, handleUpdateQuestion, handleDeleteQuestion]);

  useEffect(() => {
    console.log('Questions state updated:', questions);
  }, [questions]);

  const handleStatusChange = useCallback(
    async (questionId: number, newStatus: string) => {
      try {
        console.log(
          `Changing status for question ${questionId} to ${newStatus}`
        );
        const updatedQuestion = await questionService.updateQuestionStatus(
          questionId,
          newStatus
        );
        console.log("Updated question:", updatedQuestion);
        setQuestions((prevQuestions) =>
          prevQuestions.map((q) =>
            q.id === questionId ? { ...q, status: updatedQuestion.status } : q
          )
        );
      } catch (error) {
        console.error("Soru durumu güncellenirken hata oluştu:", error);
        setError(
          "Soru durumu güncellenirken bir hata oluştu. Lütfen tekrar deneyin."
        );
      }
    },
    []
  );

  const handleIsAnsweredChange = useCallback(async (questionId: number, newStatus: number) => {
    try {
      console.log(`Updating is_answered for question ${questionId} to ${newStatus}`);
      const updatedQuestion = await questionService.updateQuestionIsAnswered(questionId, newStatus);
      setQuestions((prevQuestions) =>
        prevQuestions.map((q) =>
          q.id === questionId ? { ...q, is_answered: updatedQuestion.is_answered } : q
        )
      );
    } catch (error) {
      console.error("Soru cevap durumu güncellenirken hata oluştu:", error);
      setError("Soru cevap durumu güncellenirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  }, []);

  const handleEditQuestion = useCallback((question: Question) => {
    setEditingQuestion(question);
  }, []);

  const handleSaveEdit = useCallback(async (editedQuestion: Question) => {
    try {
      const response = await questionService.updateQuestion(editedQuestion.id, {
        session_id: sessionId,
        question_text: editedQuestion.text,
        participant_name: editedQuestion.asker,
        status: editedQuestion.status,
        // speakers bilgisini göndermiyoruz çünkü speaker panelde bu değiştirilmiyor
      });
      
      if (response.status === 200) {
        setQuestions((prevQuestions) =>
          prevQuestions.map((q) =>
            q.id === editedQuestion.id ? {...q, ...editedQuestion} : q
          )
        );
        setEditingQuestion(null);
      } else {
        throw new Error("Failed to update question");
      }
    } catch (error) {
      console.error("Soru güncellenirken hata oluştu:", error);
      setError("Soru güncellenirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  }, [sessionId, setQuestions, setError]);

  const filteredQuestions = useMemo(() => {
    return questions.filter(
      (question) =>
        (question.text?.toLowerCase().includes(searchTerm.toLowerCase()) ?? true) &&
        (isAnsweredFilter === "all" || 
         (isAnsweredFilter === "answered" && question.is_answered === 1) ||
         (isAnsweredFilter === "unanswered" && question.is_answered === 0) ||
         (isAnsweredFilter === "rejected" && question.is_answered === 2))
    );
  }, [questions, searchTerm, isAnsweredFilter]);

  const isAnsweredCounts = useMemo(() => {
    const counts = {
      all: filteredQuestions.length,
      answered: filteredQuestions.filter(q => q.is_answered === 1).length,
      unanswered: filteredQuestions.filter(q => q.is_answered === 0).length,
      rejected: filteredQuestions.filter(q => q.is_answered === 2).length,
    };
    return counts;
  }, [filteredQuestions]);

  const isAnsweredOptions = [
    { value: "all", label: `Tüm Sorular (${isAnsweredCounts.all})` },
    { value: "answered", label: `Cevaplanmış (${isAnsweredCounts.answered})` },
    { value: "unanswered", label: `Beklemede (${isAnsweredCounts.unanswered})` },
    { value: "rejected", label: `Reddedildi (${isAnsweredCounts.rejected})` },
  ];

  const downloadExcel = () => {
    exportToExcel(filteredQuestions);
  };

  const participantLink = `${window.location.origin}/s/${participantToken}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(participantLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  if (isLoading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="m-3">
        {error}
      </Alert>
    );
  }

  return (
    <Container fluid className="speaker-panel">
      <Row>
        <Col>
          <Card className="admin-panel conference-card">
            <Card.Header className="p-4">
              <Row className="align-items-center">
                <Col md={4} className="text-center text-md-start mb-3 mb-md-0">
                  <h2 className="font-weight-bold mb-0">
                    {speakerInfo ? speakerInfo.name : "Yükleniyor..."}
                  </h2>
                  <h6 className="text-sm text-gray-600">(Konuşmacı)</h6>
                </Col>

                <Col md={4} className="text-center mb-3 mb-md-0">
                  <h3 className="text-xl font-semibold">{sessionName}</h3>
                </Col>

                <Col
                  md={4}
                  className="d-flex justify-content-center justify-content-md-end"
                >
                  <div className="view-switch ms-2">
                    <button
                      className={viewMode === "table" ? "active" : ""}
                      onClick={() => setViewMode("table")}
                    >
                      Tablo
                    </button>
                    <button
                      className={viewMode === "card" ? "active" : ""}
                      onClick={() => setViewMode("card")}
                    >
                      Kart
                    </button>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              {questions.length === 0 ? (
                <div className="text-center py-5">
                  <h4 className="mb-4">Henüz soru alınmamış</h4>
                  <p className="mb-4">
                    Oturum kodunu paylaşarak soru almaya başlayabilirsiniz.
                  </p>
                  <div className="d-flex justify-content-center mb-4" 
  onClick={() => setShowQrModal(true)}
  style={{ cursor: 'pointer' }}>
                  
                    <div className="d-block d-sm-none">
                      <QRCode value={participantLink} size={200} />{" "}
                      {/* Small screen */}
                    </div>
                    <div className="d-none d-sm-block d-md-none">
                      <QRCode value={participantLink} size={300} />{" "}
                      {/* Medium screen */}
                    </div>
                    <div className="d-none d-md-block">
                      <QRCode value={participantLink} size={400} />{" "}
                      {/* Large screen */}
                    </div>
                  </div>
                  <InputGroup className="mb-3 w-75 mx-auto">
                    <Form.Control
                      type="text"
                      value={participantLink}
                      readOnly
                    />
                    <Button variant="outline-secondary" onClick={handleCopy}>
                      <FaCopy /> {copied ? "Kopyalandı!" : "Kopyala"}
                    </Button>
                  </InputGroup>
                </div>
              ) : (
                <>
                  <Row className="mb-3 filter-select__control">
                    <Col md={6} style={{ marginBottom: "10px" }}>
                      <InputGroup>
                        <Form.Control
                          placeholder="Soru ara..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </InputGroup>
                    </Col>
                    <Col md={6} style={{ marginBottom: "10px" }}>
                      <Select
                        options={isAnsweredOptions}
                        value={isAnsweredOptions.find(
                          (option) => option.value === isAnsweredFilter
                        )}
                        onChange={(selectedOption) =>
                          setIsAnsweredFilter(selectedOption?.value || "all")
                        }
                        placeholder="Cevaplanma Durumuna Göre Filtrele"
                      />
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end mb-3">
                    <Button
                      variant="outline-secondary"
                      onClick={() => setShowQrModal(true)}
                      className="me-2"
                      disabled={!participantToken}
                    >
                      <FaQrcode /> QR Kodu Göster
                    </Button>
                    <Button variant="outline-info" onClick={downloadExcel}>
                      <FaDownload /> Excel'e Aktar
                    </Button>
                  </div>
                  {viewMode === "table" ? (
                    <QuestionTable
                      questions={filteredQuestions}
                      onStatusChange={handleStatusChange}
                      onEdit={handleEditQuestion}
                      isSpeaker={true}
                    />
                  ) : (
                    <Row  xs={1} sm={1} md={2} xl={3} className="g-4">
                      {filteredQuestions.map((question) => (
                        <Col key={question.id} md={6} lg={4}>
                          <QuestionCard
                            question={question}
                            onStatusChange={handleStatusChange}
                            onEdit={handleEditQuestion}
                            onDelete={handleDeleteQuestion}
                            isSpeaker={true}
                            handleIsAnsweredChange={handleIsAnsweredChange}
                          />
                        </Col>
                      ))}
                    </Row>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {editingQuestion && (
        <EditQuestionModal
          show={!!editingQuestion}
          onHide={() => setEditingQuestion(null)}
          question={editingQuestion}
          onSave={handleSaveEdit}
          speakers={[]} // Boş bir dizi gönderiyoruz
          sessionId={sessionId}
          isSpeakerPanel={true} // Bu prop'u ekliyoruz
        />
      )}

      <UpdatedQRModal
        show={showQrModal}
        onHide={() => setShowQrModal(false)}
        participantToken={participantToken}
      />
    </Container>
  );
};

export default SpeakerPanel;

// socketService.js
import io from 'socket.io-client';

class SocketService {
  socket = null;

  connect(sessionId) {
    if (this.socket && this.socket.connected) {
      console.log('Socket connection already exists and is connected');
      return this.socket;
    }

    const socketUrl = process.env.REACT_APP_SOCKET_URL || 'https://interedu.app';
    console.log('Connecting to Socket.IO...', socketUrl);

    this.socket = io(socketUrl, {
      query: { sessionId },
      transports: ['websocket', 'polling'],
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      timeout: 20000,
    });

    this.socket.on('connect', () => {
      console.log('Socket.IO connected successfully!');
    });

    this.socket.on('connect_error', (error) => {
      console.error('Socket.IO connection error:', error);
    });

    this.socket.on('disconnect', (reason) => {
      console.log('Socket.IO disconnected:', reason);
      if (reason === 'io server disconnect') {
        this.socket.connect();
      }
    });

    return this.socket;
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  getSocket() {
    return this.socket;
  }

  on(event, callback) {
    if (this.socket) {
      this.socket.on(event, callback);
    }
  }

  off(event, callback) {
    if (this.socket) {
      this.socket.off(event, callback);
    }
  }

  emit(event, data) {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }
}

export default new SocketService();
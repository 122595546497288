import api from './api';

const tokenService = {
  generateToken: async (sessionId, userId, role) => {
    try {
      const response = await api.post('/tokens/generate', { sessionId, userId, role });
      return response.data;
    } catch (error) {
      console.error('Error generating token:', error);
      throw error;
    }
  },

  getParticipantToken: async (sessionId) => {
    try {
      const response = await api.get(`/tokens/participant/${sessionId}`);
      return response.data.token;
    } catch (error) {
      console.error('Error getting participant token:', error);
      if (error.response && error.response.status === 404) {
        console.log('Participant token not found for session:', sessionId);
        return null;
      }
      throw error;
    }
  },

  saveToken: async (sessionId, role, token) => {
    try {
      const response = await api.post('/tokens', { sessionId, role, token });
      return response.data.token;
    } catch (error) {
      console.error('Error saving token:', error);
      throw error;
    }
  },

  getTokensForSession: async (sessionId) => {
    try {
      const response = await api.get(`/tokens/${sessionId}`);
      console.log('Token response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching tokens for session:', error);
      throw error;
    }
  },

  // tokenService.js
  validateToken: async (token) => {
    try {
      const response = await api.post('/tokens/validate', { token });
      return response.data;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  },


  
};

export default tokenService;
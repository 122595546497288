import React from 'react';
import { Button } from 'react-bootstrap';
import { 
  FaCheckSquare, 
  FaTimesCircle, 
  FaEdit, 
  FaEye, 
  FaMicrophone, 
  FaHandPaper 
} from 'react-icons/fa';
import { openQuestionInNewWindow } from '../utils/SingleQuestionDisplay';

interface Question {
  id: number;
  text: string;
  asker: string;
  status: string;
  wantsToAsk: boolean;
  onlyTakeFloor: boolean;
  speakers: Speaker[];
}

interface QuestionTableProps {
  questions: Question[];
  onStatusChange: (questionId: number, newStatus: string) => void;
  onEdit: (question: Question) => void;
  isSpeaker?: boolean;
}

const QuestionTable: React.FC<QuestionTableProps> = ({
  questions,
  onStatusChange,
  onEdit,
  isSpeaker = false,
}) => {
  const approveButtonText = isSpeaker ? 'Cevaplandı' : 'Onayla';
  const approveStatus = isSpeaker ? 'Cevaplandı' : 'Onaylandı';

  return (
    <div className="table-container">
      <table className="moderator-table">
        <thead>
          <tr>
            <th>Soru / İstek</th>
            <th>Soran</th>
            <th>Konuşmacı(lar)</th>
            <th>Durum</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {questions.map((question) => (
            <tr key={question.id}>
              <td>
              {question.onlyTakeFloor ? ( 
                  <span className="take-floor">
                    <FaHandPaper /> Söz İstiyor
                  </span>
                ) : (
                  question.text || 'Soru metni yok' 
                )}
                {question.wantsToAsk && ( 
                  <span
                    className="self-read-icon"
                    title="Soruyu kendisi okumak istiyor"
                  >
                    <FaMicrophone />
                  </span>
                )}
              </td>
              <td className="asker-cell">
                <div className="asker-name" style={{
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'normal',
                  maxWidth: '150px', // Bu değeri ihtiyaca göre ayarlayabilirsiniz
                }}>
                  {question.asker || 'Belirtilmemiş'}
                </div>
              </td>
              <td>
                {question.speakers && question.speakers.length > 0
                  ? question.speakers.map((s) => s.name).join(', ')
                  : 'Belirtilmemiş'}
              </td>
              <td>
             
                <span
    className={`status-badge status-${question.status.toLowerCase()}`}
  >
    {question.status === 'Cevaplandı' ? (
      <span className="badge bg-success">Cevaplandı</span>
    ) : (
      question.status
    )}
  </span>
              </td>
             
              <td>
                <div className="card-actions">
                  <Button
                    onClick={() => onStatusChange(question.id, approveStatus)}
                  >
                    <FaCheckSquare className="action-icon" />
                    <span>{approveButtonText}</span>
                  </Button>
                  <Button
                    onClick={() => onStatusChange(question.id, 'Reddedildi')}
                  >
                    <FaTimesCircle className="action-icon" />
                    <span>Reddet</span>
                  </Button>
                  <Button onClick={() => onEdit(question)}>
                    <FaEdit className="action-icon" />
                    <span>Düzenle</span>
                  </Button>
                  <Button onClick={() => openQuestionInNewWindow(question)}>
                    <FaEye className="action-icon" />
                    <span>Görüntüle</span>
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuestionTable;
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../assets/logo.svg';

const HeaderWrapper = styled.header<{ $isVisible: boolean }>`
  background-color: #fff;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  opacity: ${props => props.$isVisible ? 1 : 0};
  visibility: ${props => props.$isVisible ? 'visible' : 'hidden'};
  border-bottom: 1px solid rgba(229, 231, 235, 0.5);
  height: 60px;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  //max-width: 1200px;
  margin: 0 auto;
  height: 100%;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
`;

const StyledLogo = styled(Logo)`
  width: 200px;
  height: auto;
`;

const NavLinksAndAuth = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 1.5rem;
`;

const NavLink = styled(Link)<{ $isActive: boolean }>`
  color: ${props => props.$isActive ? '#3b82f6' : '#4b5563'};
  font-weight: ${props => props.$isActive ? '600' : '500'};
  text-decoration: none;
  padding: 0.25rem 0;
  transition: all 0.3s ease;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #3b82f6;
    transform: scaleX(${props => props.$isActive ? 1 : 0});
    transition: transform 0.3s ease;
  }

  &:hover::after {
    transform: scaleX(1);
  }
`;

const AuthButtons = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
`;

const AuthButton = styled.button<{ $primary?: boolean }>`
  background-color: ${props => props.$primary ? '#3b82f6' : 'transparent'};
  color: ${props => props.$primary ? 'white' : '#3b82f6'};
  border: ${props => props.$primary ? 'none' : '1px solid #3b82f6'};
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 0.75rem;
  font-size: 0.875rem;

  &:hover {
    background-color: ${props => props.$primary ? '#2563eb' : 'rgba(59, 130, 246, 0.1)'};
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #4b5563;
  z-index: 1100;
  transition: color 0.3s ease;

  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileMenuOverlay = styled.div<{ $isOpen: boolean }>`
  display: ${props => props.$isOpen ? 'block' : 'none'};
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 999;
  overflow-y: auto;
`;

const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const MobileNavLink = styled(NavLink)`
  padding: 0.75rem 0;
  border-bottom: 1px solid #e2e8f0;
  font-size: 1.1rem;
  
  &:last-child {
    border-bottom: none;
  }
`;

const MobileAuthButton = styled(AuthButton)`
  margin: 0.75rem 0;
  width: 100%;
  justify-content: center;
  font-size: 1rem;
`;

const Header: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [activeSection, setActiveSection] = useState('/');
  const navigate = useNavigate();
  const location = useLocation();

  const handleRegister = () => {
    navigate('/login?tab=register');
  };

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      
      
        if (currentScrollY > lastScrollY) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
     
     

     

      const sections = ['features', 'how-it-works', 'contact'];
      for (const section of sections) {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 100 && rect.bottom >= 100) {
            setActiveSection(`/${section}`);
            return;
          }
        }
      }
      setActiveSection('/');

      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.style.paddingTop = '60px';
    return () => {
      document.body.style.paddingTop = '0px';
    };
  }, []);

  const isActive = (path: string) => {
    if (path === '/') {
      return activeSection === '/' && location.pathname === '/';
    }
    return activeSection === path || location.pathname === path;
  };

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const headerHeight = 60;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    setIsMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <HeaderWrapper $isVisible={isVisible}>
        <Nav>
          <LogoWrapper to="/" onClick={() => scrollToSection('hero-section')}>
            <StyledLogo />
          </LogoWrapper>
          <NavLinksAndAuth>
            <NavLinks>
              <NavLink to="/" $isActive={isActive('/')} onClick={() => scrollToSection('hero-section')}>Ana Sayfa</NavLink>
              {/* <NavLink to="/features" $isActive={isActive('/features')} onClick={() => scrollToSection('features')}>Özellikler</NavLink>
              <NavLink to="/how-it-works" $isActive={isActive('/how-it-works')} onClick={() => scrollToSection('how-it-works')}>Nasıl Çalışır?</NavLink>
              <NavLink to="/contact" $isActive={isActive('/contact')} onClick={() => scrollToSection('contact')}>İletişim</NavLink> */}
            </NavLinks>
            <AuthButtons>
              <AuthButton onClick={() => navigate('/login')}>Giriş Yap</AuthButton>
              <AuthButton $primary onClick={handleRegister}>Kayıt Ol</AuthButton>
            </AuthButtons>
          </NavLinksAndAuth>
          <MobileMenuButton onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? '×' : '☰'}
          </MobileMenuButton>
        </Nav>
      </HeaderWrapper>
      <MobileMenuOverlay $isOpen={isMobileMenuOpen}>
        <MobileMenu>
          <MobileNavLink to="/" $isActive={isActive('/')} onClick={() => scrollToSection('hero-section')}>Ana Sayfa</MobileNavLink>
          {/* <MobileNavLink to="/features" $isActive={isActive('/features')} onClick={() => scrollToSection('features')}>Özellikler</MobileNavLink>
          <MobileNavLink to="/how-it-works" $isActive={isActive('/how-it-works')} onClick={() => scrollToSection('how-it-works')}>Nasıl Çalışır?</MobileNavLink>
          <MobileNavLink to="/contact" $isActive={isActive('/contact')} onClick={() => scrollToSection('contact')}>İletişim</MobileNavLink> */}
          <MobileAuthButton onClick={() => { navigate('/login'); setIsMobileMenuOpen(false); }}>Giriş Yap</MobileAuthButton>
          <MobileAuthButton $primary onClick={() => { handleRegister(); setIsMobileMenuOpen(false); }}>Kayıt Ol</MobileAuthButton>
        </MobileMenu>
      </MobileMenuOverlay>
    </>
  );
};

export default Header;
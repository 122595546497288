import React, { useState, useEffect, useCallback } from 'react';
import { Card, Button, Form, Modal, Dropdown } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import Speaker from './Speaker';
import { FaQrcode, FaEdit, FaSave, FaEllipsisH, FaTrash, FaUserPlus, FaCog, FaCheck } from 'react-icons/fa';
import './styles/Session.css';
import speakerService from '../services/speakerService';
import sessionService from '../services/sessionService';
import tokenService from '../services/tokenService';
import Settings from './settings';
import UpdatedQRModal from './UpdatedQRModal.js';

const Session = ({ session, updateSession, deleteSession, zIndex }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState('');
  const [showQrModal, setShowQrModal] = useState(false);
  const [showAddSpeakerModal, setShowAddSpeakerModal] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [newSpeaker, setNewSpeaker] = useState({ name: '', email: '', role: 'Konuşmacı' });
  const [speakers, setSpeakers] = useState([]);
  const [participantToken, setParticipantToken] = useState('');
  const [showUpdateTick, setShowUpdateTick] = useState(false);

  useEffect(() => {
    if (session?.id) {
      setEditedName(session.name || '');
      loadSpeakers();
      loadParticipantToken();
    }
  }, [session]);

  const loadSpeakers = useCallback(async () => {
    if (!session?.id) return;
    try {
      const response = await speakerService.getSpeakersBySession(session.id);
      // Filter out any undefined or null speakers
      const validSpeakers = (response.data || []).filter(speaker => speaker && speaker.id);
      setSpeakers(validSpeakers);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // 404 error means no speakers found, which is okay for a new session
        console.log('No speakers found for this session. This is normal for new sessions.');
        setSpeakers([]);
      } else {
        console.error('Error loading speakers:', error);
        // Only show an alert for errors other than 404
        alert('Konuşmacılar yüklenirken bir hata oluştu. Lütfen sayfayı yenileyin ve tekrar deneyin.');
      }
    }
  }, [session?.id]);
  const loadParticipantToken = async () => {
    if (!session?.id) return;
    try {
      const token = await tokenService.getParticipantToken(session.id);
      setParticipantToken(token);
    } catch (error) {
      console.error('Error loading participant token:', error);
    }
  };

  const handleUpdateSession = async () => {
    if (!session?.id) return;
    try {
      const updatedSessionData = { name: editedName };
      const response = await sessionService.updateSession(session.id, updatedSessionData);
      
      if (response.data && response.data.session) {
        updateSession(session.id, response.data.session);
        setIsEditing(false);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error updating session:', error);
      if (error.response && error.response.data) {
        alert(`Oturum güncellenirken bir hata oluştu: ${error.response.data.message}`);
      } else {
        alert('Oturum güncellenirken bir hata oluştu. Lütfen tekrar deneyin.');
      }
    }
  };

  const handleAddSpeaker = async () => {
    if (!session?.id) return;
    try {
      console.log('Adding new speaker:', newSpeaker);
      const speakerResponse = await speakerService.createSpeaker({
        ...newSpeaker,
        session_id: session.id
      });
      
      console.log('Speaker created:', speakerResponse.data);
  
      let updatedSpeaker = speakerResponse.data.speaker;

      try {
        const tokenResponse = await tokenService.generateToken(session.id, updatedSpeaker.id, newSpeaker.role);
        console.log('Token generated:', tokenResponse);
        
        if (tokenResponse && tokenResponse.token) {
          updatedSpeaker = { ...updatedSpeaker, token: tokenResponse.token };
        }
      } catch (tokenError) {
        console.error('Error generating token:', tokenError);
        alert('Token oluşturulurken bir hata oluştu. Konuşmacı eklendi ama token oluşturulamadı.');
      }
  
      setSpeakers(prevSpeakers => [...prevSpeakers, updatedSpeaker]);
      setShowAddSpeakerModal(false);
      setNewSpeaker({ name: '', email: '', role: 'Konuşmacı' });
    } catch (error) {
      console.error('Error adding speaker:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        alert(`Konuşmacı eklenirken bir hata oluştu: ${error.response.data.message}`);
      } else {
        alert('Konuşmacı eklenirken bir hata oluştu. Lütfen tekrar deneyin.');
      }
    }
  };

  const handleEditSpeaker = async (updatedSpeaker) => {
    if (!updatedSpeaker || !updatedSpeaker.id) {
      console.error('Invalid speaker data:', updatedSpeaker);
      return;
    }
    try {
      const response = await speakerService.updateSpeaker(updatedSpeaker.id, updatedSpeaker);
      console.log('Response from updateSpeaker:', response);
      
      if (response && response.message === 'Speaker updated successfully') {
        setSpeakers(prevSpeakers => 
          prevSpeakers.map(speaker => 
            speaker.id === updatedSpeaker.id ? { ...speaker, ...updatedSpeaker, token: response.newToken } : speaker
          )
        );
      
        
        // Token güncellendiğinde kullanıcıyı bilgilendir ve yeni token ile giriş yapmasını sağla
        if (response.newToken) {
          alert(`Rol değişikliği nedeniyle yeni bir token oluşturuldu. Lütfen tekrar giriş yapın.\nYeni Link: ${window.location.origin}/session/${response.newToken}`);
        }
        
        // Tik işaretini göster
        setShowUpdateTick(true);
        
        // 3 saniye sonra tik işaretini gizle
        setTimeout(() => setShowUpdateTick(false), 3000);
      } else {
        console.error('Unexpected response from updateSpeaker:', response);
        alert('Konuşmacı güncellenirken beklenmeyen bir yanıt alındı. Lütfen tekrar deneyin.');
      }
    } catch (error) {
      console.error('Error updating speaker:', error);
      alert('Konuşmacı güncellenirken bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  const handleDeleteSpeaker = async (speakerId) => {
    if (!speakerId) {
      console.error('Invalid speaker ID:', speakerId);
      return;
    }
    try {
      await speakerService.deleteSpeaker(speakerId);
      setSpeakers(prevSpeakers => prevSpeakers.filter(speaker => speaker.id !== speakerId));
    } catch (error) {
      console.error('Error deleting speaker:', error);
      alert('Konuşmacı silinirken bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  if (!session) {
    return null;
  }

  return (
    <Card className="session-card" style={{ zIndex }}>
      <Card.Header className="session-card-header">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center w-100">
        <div className="mb-2 mb-md-0 me-md-2 flex-grow-1 session-name-container">
  {isEditing ? (
    <Form.Control
      type="text"
      value={editedName}
      onChange={(e) => setEditedName(e.target.value)}
      onBlur={handleUpdateSession}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleUpdateSession();
        }
      }}
      autoFocus
    />
  ) : (
    <h5 className="session-name" style={{ cursor: "pointer" }} onClick={() => setIsEditing(true)}>
      {session.name}
    </h5>
  )}
</div>
          <div className="d-flex align-items-center session-actions">
            <Button onClick={() => setShowAddSpeakerModal(true)} className="me-2 add-speaker-btn">
              <FaUserPlus className="FaUserPlus" />
              <span className="btn-text">Konuşmacı Ekle</span>
            </Button>
            {isEditing ? (
              <Button variant="success" onClick={handleUpdateSession}>
                <FaSave />
              </Button>
            ) : (
              <Dropdown>
                <Dropdown.Toggle as="span" className="dropdown-toggle-custom">
                  <FaEllipsisH />
                </Dropdown.Toggle>
                <Dropdown.Menu>
              
                  <Dropdown.Item onClick={() => setShowQrModal(true)}>
                    <FaQrcode /> Oturumun QR Kodunu Göster
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => session.id && deleteSession(session.id)}>
                    <FaTrash /> Oturumu Sil
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setShowSettings(true)}>
                    <FaCog /> Ayarlar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
      </Card.Header>
      <Card.Body className="session-card-body">
        <div className="mt-3">
          <h6 className="d-flex align-items-center">
            Konuşmacılar ve Moderatörler:
            {showUpdateTick && <FaCheck className="text-success ms-2" />}
          </h6>
          {speakers && speakers.length > 0 ? (
            speakers.map((speaker) => {
              if (!speaker || !speaker.id) {
                console.error('Invalid speaker data:', speaker);
                return null;
              }
              return (
                <Speaker
                  key={speaker.id}
                  speaker={speaker}
                  onEdit={handleEditSpeaker}
                  onDelete={() => handleDeleteSpeaker(speaker.id)}
                  sessionId={session.id}
                />
              );
            })
          ) : (
            <p>Bu oturumda henüz konuşmacı veya moderatör bulunmamaktadır.</p>
          )}
        </div>
      </Card.Body>

      {/* Settings Modal */}
      <Settings show={showSettings} onHide={() => setShowSettings(false)} sessionId={session.id} />

      {/* QR Code Modal */}
      <UpdatedQRModal show={showQrModal} onHide={() => setShowQrModal(false)} participantToken={participantToken} />

      {/* Add Speaker Modal */}
      <Modal show={showAddSpeakerModal} onHide={() => setShowAddSpeakerModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Yeni Konuşmacı/Moderatör Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>İsim</Form.Label>
              <Form.Control
                type="text"
                value={newSpeaker.name}
                onChange={(e) => setNewSpeaker({ ...newSpeaker, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={newSpeaker.email}
                onChange={(e) => setNewSpeaker({ ...newSpeaker, email: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Rol</Form.Label>
              <Form.Control
                as="select"
                value={newSpeaker.role}
                onChange={(e) => setNewSpeaker({ ...newSpeaker, role: e.target.value })}
              >
                <option>Konuşmacı</option>
                <option>Moderatör</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddSpeakerModal(false)}>
            İptal
          </Button>
          <Button variant="primary" onClick={handleAddSpeaker}>
            Ekle
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default Session;
import React, { useState } from 'react';
import styled from 'styled-components';
import { ArrowRight, Send } from 'lucide-react';

const ContactSection = styled.section`
  padding: 8rem 0;
  background: linear-gradient(135deg, #1e3a8a 0%, #3b82f6 100%);
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ContactForm = styled.form`
  max-width: 36rem;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
`;

const FormGroup = styled.div`
  margin-bottom: 2rem;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #ffffff;
  font-weight: 500;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  &:focus {
    outline: none;
    border-color: #60a5fa;
    box-shadow: 0 0 0 2px rgba(96, 165, 250, 0.5);
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const FormTextarea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  &:focus {
    outline: none;
    border-color: #60a5fa;
    box-shadow: 0 0 0 2px rgba(96, 165, 250, 0.5);
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const FormButton = styled.button`
  width: 100%;
  background-color: #3b82f6;
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #2563eb;
    transform: translateY(-2px);
  }
  &:active {
    transform: translateY(0);
  }
`;

const Contact: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Burada form gönderme işlemini gerçekleştirebilirsiniz
    console.log('Form gönderildi:', { name, email, message });
    // Form gönderildikten sonra alanları temizle
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <ContactSection id="contact">
      <div className="container">
        <SectionTitle>Bizimle İletişime Geçin</SectionTitle>
        <ContactForm onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabel htmlFor="name">Adınız</FormLabel>
            <FormInput
              type="text"
              id="name"
              name="name"
              placeholder="Adınızı girin"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="email">E-posta Adresiniz</FormLabel>
            <FormInput
              type="email"
              id="email"
              name="email"
              placeholder="E-posta adresinizi girin"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="message">Mesajınız</FormLabel>
            <FormTextarea
              id="message"
              name="message"
              rows={4}
              placeholder="Mesajınızı buraya yazın"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></FormTextarea>
          </FormGroup>
          <FormButton type="submit">
            Gönder 
            <Send size={18} style={{marginLeft: '0.5rem'}} />
          </FormButton>
        </ContactForm>
      </div>
    </ContactSection>
  );
};

export default Contact;
import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaBolt, FaCalendarPlus, FaList, FaChartLine, FaArrowRight } from 'react-icons/fa';

const Dashboard: React.FC = () => {
  return (
    <Container fluid className="dashboard-container">
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

          body {
            font-family: 'Poppins', sans-serif;
          }

          .dashboard-container {
            min-height: 100vh;
            padding: 2rem;
            position: relative;
            overflow: hidden;
         //   background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
          }

          .welcome-text {
            color: #ffffff;
            text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
            margin-bottom: 2rem;
            position: relative;
            z-index: 1;
            font-size: 3rem;
            font-weight: 700;
            text-align: center;
          }

          .glass-card {
            position: relative;
            overflow: hidden;
            background: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(10px);
            border-radius: 20px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
            transition: all 0.3s ease-in-out;
            height: 550px;
            z-index: 1;
            overflow: hidden;
          }

          .glass-card::before {
            content: "";
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              120deg,
              transparent,
              rgba(255, 255, 255, 0.3),
              transparent
            );
            transition: 0.5s;
          }

          .glass-card:hover::before {
            left: 100%;
          }

          .glass-card:hover {
            transform: translateY(-10px) scale(1.02);
            box-shadow: 0 15px 50px 0 rgba(31, 38, 135, 0.5);
          }

          .card-body {
            padding: 2rem;
            display: flex;
            flex-direction: column;
            height: 100%;
          }

          .card-title {
            font-size: 1.8rem;
            font-weight: 600;
            color: #ffffff;
            text-shadow: 1px 1px 2px rgba(0,0,0,0.3);
            margin-bottom: 1rem;
            position: relative;
          }

          .card-title::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 50px;
            height: 3px;
            background-color: #ffffff;
          }

          .card-text {
            font-size: 1rem;
            color: rgba(255, 255, 255, 0.8);
            flex-grow: 1;
          }

          .icon-container {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto 20px;
            background: rgba(255, 255, 255, 0.2);
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
            transition: all 0.3s ease;
          }

          .glass-card:hover .icon-container {
            transform: scale(1.1) rotate(5deg);
          }

          .btn {
            border-radius: 30px;
            padding: 10px 20px;
            font-weight: 600;
            transition: all 0.3s ease;
            border: none;
            background: rgba(255, 255, 255, 0.2);
            color: #ffffff;
            backdrop-filter: blur(5px);
            margin-top: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .btn:hover {
            background: rgba(255, 255, 255, 0.3);
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
            transform: translateY(-2px);
          }

          .btn-icon {
            margin-left: 10px;
            transition: transform 0.3s ease;
          }

          .btn:hover .btn-icon {
            transform: translateX(5px);
          }

          .feature-list {
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 2.5rem;
          }

          .feature-list li {
            margin-bottom: 0.9rem;
            color: rgba(255, 255, 255, 0.8);
            display: flex;
            align-items: center;
          }

          .feature-list li::before {
            content: "•";
            color: #fdbb2d;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-right: 0.5em;
          }

          @media (max-width: 768px) {
            .glass-card {
              height: auto;
              margin-bottom: 2rem;
            }
          }
        `}
      </style>
      <h1 className="welcome-text">Yönetim Merkezi</h1>
      <Row>
        <Col lg={4} md={6} className="mb-4">
          <Card className="glass-card">
            <Card.Body>
              <div className="icon-container">
                <FaBolt size={40} color="#ffffff" />
              </div>
              <Card.Title className="text-center">Hızlı Etkinlik</Card.Title>
              <Card.Text>
                Anında bir etkinlik oluşturun ve başlatın. Ders, sunum gibi tek kişilik etkinlikler için idealdir.
              </Card.Text>
              <ul className="feature-list">
                <li>Hızlı kurulum</li>
                <li>Otomatik oturum oluşturma</li>
                <li>Anında katılımcı davetleri</li>
              </ul>
              <Button as={Link} to="/quickConference" variant="primary">
                Hızlı Etkinlik Oluştur
                <FaArrowRight className="btn-icon" />
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} md={6} className="mb-4">
          <Card className="glass-card">
            <Card.Body>
              <div className="icon-container">
                <FaCalendarPlus size={40} color="#ffffff" />
              </div>
              <Card.Title className="text-center">Profesyonel Etkinlik</Card.Title>
              <Card.Text>
                Detaylı bir konferans planı oluşturun. Büyük etkinlikler ve uzun vadeli planlamalar için uygundur.
              </Card.Text>
              <ul className="feature-list">
                <li>Özelleştirilebilir oturumlar</li>
                <li>Konuşmacı yönetimi</li>
                <li>Gelişmiş planlama araçları</li>
              </ul>
              <Button as={Link} to="/newConference" variant="success">
                Yeni Konferans Ekle
                <FaArrowRight className="btn-icon" />
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} md={6} className="mb-4">
          <Card className="glass-card">
            <Card.Body>
              <div className="icon-container">
                <FaList size={40} color="#ffffff" />
              </div>
              <Card.Title className="text-center">Etkinliklerim</Card.Title>
              <Card.Text>
                Tüm etkinliklerinizi görüntüleyin, düzenleyin ve yönetin. Genel bir bakış için idealdir.
              </Card.Text>
              <ul className="feature-list">
                <li>Etkinlik geçmişi</li>
                <li>Düzenleme ve silme seçenekleri</li>
                <li>Detaylı etkinlik bilgileri</li>
              </ul>
              <Button as={Link} to="/conferenceList" variant="info">
                Etkinlikleri Görüntüle
                <FaArrowRight className="btn-icon" />
              </Button>
            </Card.Body>
          </Card>
        </Col>
        {/* <Col lg={3} md={6} className="mb-4">
          <Card className="glass-card">
            <Card.Body>
              <div className="icon-container">
                <FaChartLine size={40} color="#ffffff" />
              </div>
              <Card.Title className="text-center">İstatistikler</Card.Title>
              <Card.Text>
                Konferans ve oturum istatistiklerinizi görüntüleyin. Performans analizi için mükemmeldir.
              </Card.Text>
              <ul className="feature-list">
                <li>Katılımcı analizleri</li>
                <li>Oturum popülerliği</li>
                <li>Trend raporları</li>
              </ul>
              <Button as={Link} to="/statistics" variant="warning">
                İstatistikleri Görüntüle
                <FaArrowRight className="btn-icon" />
              </Button>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
    </Container>
  );
};

export default Dashboard;
import React, { useState, useEffect, useRef } from 'react';
import { FaPalette } from 'react-icons/fa';

export const backgrounds = [
  { name: 'Midnight Sky', value: 'linear-gradient(to bottom right, #111827, #2b4255 0%, #000428)' },
  { name: 'Deep Night Blue', value: 'linear-gradient(220.55deg, #2b4255 0%, #0E2C5E 100%)' },
  { name: 'Ocean Depths', value: 'linear-gradient(to right, #000428, #082742)' },
  
  { 
    name: 'Midnight Aurora', 
    value: 'linear-gradient(135deg, #0F2027 0%, #203A43 50%, #2C5364 100%)'
  },
 
  { 
    name: 'Twilight Forest', 
    value: 'linear-gradient(to right, #0f0c29, #302b63, #24243e)'
  },
  { 
    name: 'Northern Lights', 
    value: 'linear-gradient(to bottom right, #1f1c2c, #2e2257, #24243e, #0f2027)'
  },
  { 
    name: 'Obsidian Glass', 
    value: 'radial-gradient(circle at 30% 50%, #0a192f 0%, #203a43 50%, #2c5364 100%)'
  },
  { 
    name: 'Cosmic Fusion', 
    value: 'linear-gradient(45deg, #141e30, #243b55, #1e2a78)'
  },

  { 
    name: 'Abyssal Ocean', 
    value: 'radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%)'
  },

  { 
    name: 'Shadows of Night', 
    value: 'linear-gradient(to bottom, #0f2027, #203a43, #2c5364, #192734)'
  } 
];

export const BackgroundSelector = ({ currentBackground, setCurrentBackground }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const selectorRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      const { innerWidth, innerHeight } = window;
      
      // Farenin sağ alt köşeye yakın olup olmadığını kontrol et
      const isNearBottomRight = 
        clientX > innerWidth - 100 && clientY > innerHeight - 100;
      
      setIsVisible(isNearBottomRight || isOpen);
    };

    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleBackgroundChange = (bg) => {
    setCurrentBackground(bg);
    localStorage.setItem('currentBackground', JSON.stringify(bg));
    setIsOpen(false);
  };

  const toggleSelector = () => {
    setIsOpen(!isOpen);
    setIsVisible(true);
  };

  return (
    <div 
      ref={selectorRef}
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
        transition: 'opacity 0.3s ease-in-out',
        opacity: isVisible ? 1 : 0,
        pointerEvents: isVisible ? 'auto' : 'none',
      }}
    >
      <button
        onClick={toggleSelector}
        style={{
          background: 'white',
          border: 'none',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
        }}
      >
        <FaPalette />
      </button>
      {isOpen && (
        <div style={{
          position: 'absolute',
          bottom: '50px',
          right: '0',
          background: 'white',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
        }}>
          {backgrounds.map((bg) => (
            <div
              key={bg.value}
              onClick={() => handleBackgroundChange(bg)}
              style={{
                width: '30px',
                height: '30px',
                background: bg.value,
                margin: '5px',
                cursor: 'pointer',
                border: currentBackground.value === bg.value ? '2px solid blue' : 'none',
              }}
              title={bg.name}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default BackgroundSelector;
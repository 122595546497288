          import api from './api';
          import { mapStatus, reverseMapStatus } from './statusMapping';

          const questionService = {
            // ... diğer fonksiyonlar ...
            getApprovedQuestionsBySession: async (sessionId) => {
              try {
                const response = await api.get(`/questions/session/${sessionId}/approved`);
                return response.data.map(question => ({
                  ...question,
                  status: mapStatus(question.status)
                }));
              } catch (error) {
                console.error('Error fetching approved questions:', error);
                throw error;
              }
            },

deleteQuestion: async (questionId) => {
  try {
    const response = await api.delete(`/questions/${questionId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting question:', error);
    throw error;
  }
},
            getQuestionsForSpeaker: async (sessionId, speakerId) => {
              try {
                console.log(`Fetching questions for session ${sessionId} and speaker ${speakerId}`);
                const response = await api.get(`/questions/session/${sessionId}/speaker/${speakerId}`);
                console.log('Received questions:', response.data);
                return response.data.map(question => ({
                  ...question,
                  text: question.text || question.question_text || '',
                  status: mapStatus(question.status),
                  is_answered: question.is_answered // Bu satırı ekleyin
                }));
              } catch (error) {
                console.error('Error fetching questions for speaker:', error);
                throw error;
              }
            },
          getQuestionsBySession: async (sessionId) => {
            try {
              const response = await api.get(`/questions/session/${sessionId}`);
              console.log('Raw response from server:', response.data);
              return response.data.map(question => ({
                ...question,
                text: question.text || question.question_text, // Her iki alanı da kontrol et
                status: mapStatus(question.status)
              }));
            } catch (error) {
              console.error('Error fetching questions:', error);
              throw error;
            }
          },

            // questionService.js

            updateQuestionStatus: async (questionId, status) => {
              try {
                console.log(`Updating question status: ID ${questionId}, New status: ${status}`);
                const response = await api.put(`/questions/${questionId}/status`, { status });
                console.log('Server response:', response.data);
                return {
                  ...response.data,
                  status: mapStatus(response.data.status)
                };
              } catch (error) {
                console.error('Error updating question status:', error);
                if (error.response) {
                  console.error('Error response:', error.response.data);
                  console.error('Error status:', error.response.status);
                }
                throw error;
              }
            },

            
            updateQuestion: async (id, questionData) => {
              console.log('Sending update request for question:', id, JSON.stringify(questionData, null, 2));
              try {
                const response = await api.put(`/questions/${id}`, questionData);
                console.log('Update response:', response.data);
                return response;
              } catch (error) {
                console.error('Error in updateQuestion:', error);
                if (error.response) {
                  console.error('Error response data:', error.response.data);
                }
                throw error;
              }
            },
           updateQuestionIsAnswered: async (questionId, isAnswered) => {
              try {
                const response = await api.put(`/questions/${questionId}/is-answered`, { is_answered: isAnswered });
                return response.data;
              } catch (error) {
                console.error('Error in updateQuestionIsAnswered:', error);
                throw error;
              }
            },
            

            // Diğer gerekli fonksiyonları buraya ekleyin (örneğin, soru onaylama, reddetme, cevaplama)

createQuestion: async (questionData) => {
  try {
    const response = await api.post('/questions', questionData);
    return response.data;
  } catch (error) {
    console.error('Error creating question:', error);
    throw error;
  }
},

          };

          

          export default questionService;
import React, { useState, useEffect } from 'react';
import { Card, Button, Form, InputGroup } from 'react-bootstrap';
import { FaSave, FaTimes, FaTrash, FaCopy } from 'react-icons/fa';
import tokenService from '../services/tokenService'; // Bu import'u ekleyin
import './styles/Speaker.css';

const Speaker = ({ speaker, onEdit, onDelete, sessionId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(speaker.name);
  const [email, setEmail] = useState(speaker.email);
  const [role, setRole] = useState(speaker.role);
  const [copied, setCopied] = useState(false);
  const [token, setToken] = useState('');
  const [isEditingActive, setIsEditingActive] = useState(false);

  useEffect(() => {
    fetchToken();
    if (isEditing) {
      setTimeout(() => setIsEditingActive(true), 10);
    } else {
      setIsEditingActive(false);
    }
  }, [speaker.id, sessionId, isEditing]);

  const fetchToken = async () => {
    try {
      const response = await tokenService.getTokensForSession(sessionId);
      const speakerToken = response.speakers?.find(t => String(t.userId) === String(speaker.id))?.token;
      const moderatorToken = response.moderators?.find(t => String(t.userId) === String(speaker.id))?.token;
      setToken(speakerToken || moderatorToken || '');
    } catch (error) {
      console.error('Token yüklenirken hata oluştu:', error);
      setToken('');
    }
  };

  const handleSave = () => {
    onEdit({ ...speaker, name, email, role });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setName(speaker.name);
    setEmail(speaker.email);
    setRole(speaker.role);
    setIsEditing(false);
  };

  const handleCardClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  // Yeni eklenen fonksiyon
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSave();
    }
  };

  const handleCopy = () => {
    const link = `${window.location.origin}/session/${token}`;
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Card 
      className="speaker-card position-relative" 
      onClick={handleCardClick}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          handleCancel();
        }
      }}
      tabIndex={0}
    >
      {!isEditing && (
        <Button
          className="delete-button"
          style={{ border: 'none', background: 'none', fontSize: '1.2rem' }}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(speaker.id);
          }}
        >
          <FaTrash /> Sil
        </Button>
      )}
      <Card.Body className="speaker-card-body pt-4">
        {isEditing ? (
          <div className={`speaker-edit-form w-100 ${isEditingActive ? 'active' : ''}`} onClick={(e) => e.stopPropagation()}>
            <Form.Control
              type="text"
              placeholder="Konuşmacı Adı"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mb-2"
              onKeyDown={handleKeyDown}
            />
            
            <Form.Control
              type="email"
              placeholder="E-posta"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mb-2"
              onKeyDown={handleKeyDown}
            />
            <Form.Control
              as="select"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="mb-2"
              onKeyDown={handleKeyDown}
            >
              <option>Konuşmacı</option>
              <option>Moderatör</option>
            </Form.Control>
            <div className="mt-3">
              <InputGroup className="flex-nowrap">
                <a
                  href={token ? `${window.location.origin}/session/${token}` : '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="form-control flex-grow-1"
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    color: '#007bff',
                    textDecoration: 'underline',
                    cursor: token ? 'pointer' : 'default'
                  }}
                >
                  {token ? `${window.location.origin}/session/${token}` : 'Token bulunamadı'}
                </a>
                <Button 
                  variant="outline-secondary" 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopy();
                  }}
                  className="d-flex align-items-center"
                  style={{ padding: '0.25rem 0.5rem' }} // Butonu küçültmek için
                  disabled={!token}
                >
                  <FaCopy className="me-2" /> {copied ? 'Kopyalandı!' : 'Kopyala'}
                </Button>
              </InputGroup>
            </div>
            <div className="speaker-actions mt-2">
              <Button variant="primary" size="sm" onClick={handleSave}>
                <FaSave /> Kaydet
              </Button>
              <Button variant="secondary" size="sm" onClick={handleCancel} className="ms-2">
                <FaTimes /> İptal
              </Button>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column">
            <div className="speaker-info mb-2">
              <h5 className="speaker-name">{name}</h5>
              <p className="speaker-email">{email}</p>
              <p className="speaker-role">{role}</p>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default Speaker;
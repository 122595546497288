import React from 'react';
import styled from 'styled-components';
import { Users, MessageSquare, Mic, Hand } from 'lucide-react';

const FeaturesSection = styled.section`
  padding: 6rem 0;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 4rem;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.7);
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2.5rem;
`;

const FeatureCard = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
`;

const FeatureIcon = styled.div`
  background-color: #e0e7ff;
  color: #3b82f6;
  padding: 1rem;
  border-radius: 9999px;
  display: inline-block;
  margin-bottom: 1.5rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  color: #fff;;
`;

const FeatureDescription = styled.p`
  color: #64748b;
  line-height: 1.6;
`;

const Feature: React.FC<{ icon: React.ReactNode; title: string; description: string }> = ({ icon, title, description }) => (
  <FeatureCard>
    <FeatureIcon>{icon}</FeatureIcon>
    <FeatureTitle>{title}</FeatureTitle>
    <FeatureDescription>{description}</FeatureDescription>
  </FeatureCard>
);

const Features: React.FC = () => {
  const features = [
    {
      icon: <Users size={32} />,
      title: "Yönetim Merkezi",
      description: "Eğitimlerinizi kolayca yönetin, oturumlar oluşturun ve kullanıcı yetkilerini düzenleyin."
    },
    {
      icon: <MessageSquare size={32} />,
      title: "Moderasyon Arayüzü",
      description: "Soruları gerçek zamanlı yönetin, onaylayın ve konuşmacılara atayın."
    },
    {
      icon: <Mic size={32} />,
      title: "Konuşmacı Portalı",
      description: "Oturum bilgilerini görüntüleyin ve size atanan soruları cevaplayın."
    },
    {
      icon: <Hand size={32} />,
      title: "Katılımcı Arayüzü",
      description: "QR kod ile giriş yapın, soru sorun ve soru durumunuzu takip edin."
    }
  ];

  return (
    <FeaturesSection id="features">
      <div className="container">
        <SectionTitle>Özellikler</SectionTitle>
        <FeaturesGrid>
          {features.map((feature, index) => (
            <Feature key={index} {...feature} />
          ))}
        </FeaturesGrid>
      </div>
    </FeaturesSection>
  );
};

export default Features;
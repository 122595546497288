import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Button, Form, Modal, Alert, Spinner, Row, Col } from 'react-bootstrap'
import axios from 'axios';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { FaMicrophone, FaPaperPlane, FaHandPaper } from 'react-icons/fa';
import { AlignLeft } from 'lucide-react';

interface Speaker {
  id: string;
  name: string;
}

interface Session {
  id: string;
  name: string;
  description: string;
  speakers: Speaker[];
}

const ParticipantPanel: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [session, setSession] = useState<Session | null>(null);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [question, setQuestion] = useState('');
  const [name, setName] = useState('');
  const [selectedSpeakers, setSelectedSpeakers] = useState<string[]>([]);
  const [readQuestion, setReadQuestion] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sortedSpeakers, setSortedSpeakers] = useState<Speaker[]>([]);
  const [showSpeakRequestConfirm, setShowSpeakRequestConfirm] = useState(false);
  const [nameError, setNameError] = useState<string | null>(null);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`/api/sessions/validate/${token}`);
        if (response.data && response.data.id) {
          setSession(response.data);
          // Konuşmacıları sırala
          if (response.data.speakers) {
            const sorted = [...response.data.speakers].sort((a, b) => {
              const nameComparison = a.name.localeCompare(b.name);
              if (nameComparison === 0) {
                return parseInt(a.id) - parseInt(b.id);
              }
              return nameComparison;
            });
            setSortedSpeakers(sorted);
          }
        } else {
          setError('Geçersiz oturum verisi alındı.');
        }
      } catch (error) {
        console.error('Oturum verisi alınırken hata oluştu:', error);
        setError('Oturum bilgileri alınamadı. Lütfen daha sonra tekrar deneyin.');
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      fetchSessionData();
    }
  }, [token]);

  const handleSpeakerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedSpeakers(prevSelected => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter(speaker => speaker !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (name.length < 3 && !isAnonymous) {
      setNameError("İsim en az 3 karakter olmalıdır.");
      return;
    }
    setNameError(null);
    setShowSummary(true);
  };

  const handleConfirmSubmit = async (onlyRequestToSpeak = false) => {
    try {
      const payload = {
        sessionId: session?.id,
        name: isAnonymous ? 'Anonim' : name,
        question: onlyRequestToSpeak ? 'Söz isteme talebi' : question,
        speakerIds: selectedSpeakers,
        readByParticipant: readQuestion,
        onlyRequestToSpeak
      };

      console.log('Sending payload:', payload);

      const response = await axios.post('/api/questions', payload);

      if (response.status === 201) {
        setShowSummary(false);
        setQuestion('');
        setSelectedSpeakers([]);
        setReadQuestion(false);
        setSuccess(onlyRequestToSpeak ? 'Söz isteğiniz başarıyla gönderildi!' : 'Sorunuz başarıyla gönderildi!');
      } else {
        throw new Error('Beklenmeyen bir yanıt alındı');
      }
    } catch (error) {
      console.error('Soru/İstek gönderilirken hata:', error);
      if (axios.isAxiosError(error) && error.response) {
        const errorMessage = error.response.data.message || error.message;
        setError(`Soru/İstek gönderilirken bir hata oluştu: ${errorMessage}`);
      } else {
        setError('Soru/İstek gönderilirken bilinmeyen bir hata oluştu. Lütfen tekrar deneyin.');
      }
    }
  };
  const handleSpeakRequest = () => {
    if (name.length < 3 && !isAnonymous) {
      setNameError("İsim en az 3 karakter olmalıdır.");
      return;
    }
    setNameError(null);
    setReadQuestion(false);
    setShowSpeakRequestConfirm(true);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(0, 50);
    setName(value);
    setNameError(null);
  };

  const handleQuestionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.slice(0, 500);
    setQuestion(value);
    setCharCount(value.length);
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="m-3">
        {error}
      </Alert>
    );
  }

  if (!session) {
    return (
      <Alert variant="warning" className="m-3">
        Oturum bulunamadı. Lütfen geçerli bir bağlantı kullandığınızdan emin olun.
      </Alert>
    );
  }

  return (
    <>
      <style>
        {`
          .conference-card {
            max-width: 28rem;
            min-width: 300px;
            width: 100%;
            margin: 1rem;
          }
  
          .button-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 10px;
          }
  
          .action-button {
            flex: 1 1 calc(50% - 5px);
            min-width: 120px;
          }
  
          @media (max-width: 576px) {
            .action-button {
              flex: 1 1 100%;
            }
          }
        `}
      </style>
      <div className="admin-panel d-flex align-items-center justify-content-center min-vh-100">
        <Card className="conference-card">
          <Card.Header className="conference-card-header text-center">
            <h2 className="font-weight-bold mt-2">{session.name}</h2>
          </Card.Header>
          <Card.Body>
            {success && (
              <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                {success}
              </Alert>
            )}
            <Form onSubmit={handleFormSubmit}>
              <Form.Group controlId="name" className="mb-3">
                <Form.Label>İsim (Opsiyonel)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="İsminizi girin (en fazla 50 karakter)"
                  value={name}
                  onChange={handleNameChange}
                  disabled={isAnonymous}
                  isInvalid={!!nameError}
                  maxLength={50}
                />
                <Form.Control.Feedback type="invalid">
                  {nameError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="anonymous" className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Anonim olarak katıl"
                  checked={isAnonymous}
                  onChange={() => {
                    setIsAnonymous(!isAnonymous);
                    if (!isAnonymous) {
                      setReadQuestion(false);
                    }
                  }}
                />
              </Form.Group>
              <Form.Group controlId="question" className="mb-3">
                <Form.Label>Sorunuz</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Sorunuzu buraya yazın (en fazla 500 karakter)"
                  value={question}
                  onChange={handleQuestionChange}
                  required
                  maxLength={500}
                />
                <div className="text-end mt-1">
                  <small className="text-white">{charCount}/500</small>
                </div>
              </Form.Group>
  
              <div className="d-flex align-items-center mb-3">
                <Form.Check
                  type="checkbox"
                  id="readQuestion"
                  checked={readQuestion}
                  onChange={() => setReadQuestion(!readQuestion)}
                  disabled={isAnonymous}
                />
                <label htmlFor="readQuestion" className="ms-2 d-flex align-items-center">
                  <FaMicrophone className="me-2" />
                  Soruyu kendim okumak istiyorum
                </label>
              </div>
  
              <hr />
  
              {sortedSpeakers.length > 0 && (
                <Form.Group controlId="speaker" className="mb-3">
                  <Form.Label>Konuşmacı(lar)</Form.Label>
                  {sortedSpeakers.map((speaker) => (
                    <Form.Check
                      key={speaker.id}
                      type="checkbox"
                      label={speaker.name}
                      value={speaker.id}
                      checked={selectedSpeakers.includes(speaker.id)}
                      onChange={handleSpeakerChange}
                    />
                  ))}
                </Form.Group>
              )}
              <div className="button-container">
                <Button variant="primary" type="submit" className="action-button">
                  <FaPaperPlane className="me-2" />
                  Soru Gönder
                </Button>
                <Button 
                  variant="outline-secondary" 
                  onClick={handleSpeakRequest} 
                  disabled={isAnonymous}
                  className="action-button"
                >
                  <FaHandPaper className="me-2" />
                  Sadece Söz İste
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
  
        <Modal show={showSummary} onHide={() => setShowSummary(false)}>
          <Modal.Header closeButton className="d-flex align-items-center justify-content-between">
            <Logo style={{ width: 100, height: 30 }}/>
            <div></div>
          </Modal.Header>
          <Modal.Body>
            <h4 className="text-center mb-4">Soru Özeti</h4>
            <Row className="mb-2">
              <Col xs={4}><strong>İsim:</strong></Col>
              <Col xs={8}>{isAnonymous ? 'Anonim' : name}</Col>
            </Row>
            <Row className="mb-2">
              <Col xs={4}><strong>Soru:</strong></Col>
              <Col xs={8}>{question}</Col>
            </Row>
            {sortedSpeakers.length > 0 && (
              <Row className="mb-2">
                <Col xs={4}><strong>Konuşmacı:</strong></Col>
                <Col xs={8}>
                  {selectedSpeakers.length > 0
                    ? selectedSpeakers.map(id => sortedSpeakers.find(s => s.id === id)?.name).join(', ')
                    : 'Belirtilmemiş'}
                </Col>
              </Row>
            )}
            <Row className="mb-2">
              <Col xs={4}><strong>Soruyu Kendim Okumak İstiyorum:</strong></Col>
              <Col xs={8}>{readQuestion ? 'Evet' : 'Hayır'}</Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowSummary(false)}>
              İptal
            </Button>
            <Button variant="primary" onClick={() => handleConfirmSubmit(false)}>
              Onayla ve Gönder
            </Button>
          </Modal.Footer>
        </Modal>
  
        <Modal show={showSpeakRequestConfirm} onHide={() => setShowSpeakRequestConfirm(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Söz İsteme Onayı</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Sadece söz istemek istediğinizden emin misiniz?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowSpeakRequestConfirm(false)}>
              İptal
            </Button>
            <Button variant="primary" onClick={() => {
              handleConfirmSubmit(true);
              setShowSpeakRequestConfirm(false);
            }}>
              Onayla
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ParticipantPanel;
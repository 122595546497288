import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import '../../styles/moderator.css';
import * as COLORS from '../../styles/colors';

interface Speaker {
  id: string;
  name: string;
}

interface Question {
  id: number;
  text: string;
  asker: string;
  status: string;
  wantsToAsk: boolean;
  onlyTakeFloor: boolean;
  speakers: Speaker[];
  session_id?: string; // Bu alanı ekleyin
}

interface EditQuestionModalProps {
  show: boolean;
  onHide: () => void;
  question: Question;
  onSave: (question: Question) => void;
  speakers: Speaker[];
  sessionId: string; // Bu prop'u ekleyin
  isSpeakerPanel: boolean; // Bu prop'u ekliyoruz
}

const EditQuestionModal: React.FC<EditQuestionModalProps> = ({ 
  show, 
  onHide, 
  question, 
  onSave, 
  speakers, 
  sessionId, 
  isSpeakerPanel 
}) => {
  const [editedQuestion, setEditedQuestion] = useState<Question>(question);

  useEffect(() => {
    setEditedQuestion({...question, session_id: sessionId});
  }, [question, sessionId]);

  const handleSave = () => {
    onSave(editedQuestion);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Soru Düzenle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Soru</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={editedQuestion.text}
              onChange={(e) => setEditedQuestion({ ...editedQuestion, text: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Soran</Form.Label>
            <Form.Control
              type="text"
              value={editedQuestion.asker}
              onChange={(e) => setEditedQuestion({ ...editedQuestion, asker: e.target.value })}
            />
          </Form.Group>
          {!isSpeakerPanel && ( // Sadece moderator panelde göster
            <Form.Group>
              <Form.Label>Konuşmacı(lar)</Form.Label>
              <Select
                isMulti
                options={speakers.map(speaker => ({ 
                  value: speaker.id.toString(), 
                  label: speaker.name 
                }))}
                value={editedQuestion.speakers.map(speaker => ({
                  value: speaker.id.toString(),
                  label: speaker.name
                }))}
                onChange={(selectedOptions) => {
                  const newSpeakers = selectedOptions ? selectedOptions.map((option: any) => ({
                    id: option.value,
                    name: option.label
                  })) : [];
                  setEditedQuestion({ ...editedQuestion, speakers: newSpeakers });
                }}
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button style={{backgroundColor: '#606264', color: 'white' }} 
        onMouseEnter={(e) => {e.target.style.backgroundColor = '#fff'; e.target.style.color = 'black';}} onMouseLeave={(e) => {e.target.style.backgroundColor = '#606264'; e.target.style.color = 'white';}}onClick={onHide}>
          İptal
        </Button>
        <Button style={{backgroundColor: COLORS.BUTTON.PRIMARY.BACKGROUND, color: COLORS.BUTTON.PRIMARY.TEXT }} 
        onMouseEnter={(e) => {e.target.style.backgroundColor = COLORS.BUTTON.SECONDARY.HOVER; e.target.style.color = COLORS.BUTTON.PRIMARY.HOVER.TEXT;}} 
        onMouseLeave={(e) => {e.target.style.backgroundColor = COLORS.BUTTON.PRIMARY.BACKGROUND; e.target.style.color = COLORS.BUTTON.PRIMARY.TEXT;}} 
        onMouseDown={(e) => {e.target.style.backgroundColor = COLORS.BUTTON.SECONDARY.BACKGROUND; e.target.style.color = COLORS.BUTTON.PRIMARY.TEXT;}}
        onClick={handleSave}>
          Kaydet
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditQuestionModal;
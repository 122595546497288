import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import tokenService from '../services/tokenService';

const SessionRedirect = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const validateAndRedirect = async () => {
      try {
        const tokenInfo = await tokenService.validateToken(token);
        if (tokenInfo.valid) {
          if (tokenInfo.role === 'moderator') {
            navigate(`/moderator/${token}`, { replace: true });
          } else if (tokenInfo.role === 'speaker') {
            navigate(`/speaker/${token}`, { replace: true });
          } else {
            // Katılımcı veya başka bir rol için yönlendirme
            navigate(`/participant/${token}`, { replace: true });
          }
        } else {
          navigate('/error', { replace: true, state: { message: 'Geçersiz token' } });
        }
      } catch (error) {
        console.error('Token doğrulama hatası:', error);
        navigate('/error', { replace: true, state: { message: 'Token doğrulama hatası' } });
      }
    };

    validateAndRedirect();
  }, [token, navigate]);

  return <div>Yönlendiriliyor...</div>;
};

export default SessionRedirect;
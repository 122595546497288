import React, { useState, useRef, useEffect } from 'react';
import { FaMicrophone, FaHandPaper, FaTrash } from "react-icons/fa";
import { motion, useAnimation } from 'framer-motion';
import { openQuestionInNewWindow } from '../utils/SingleQuestionDisplay';
import * as COLORS from '../../styles/colors';

const QuestionCard = ({ question, onStatusChange, onEdit, onView, onDelete, isSpeaker = false }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isApproved, setIsApproved] = useState(question.status === 'Onaylandı' || question.status === 'Cevaplandı');
  const [swipeProgress, setSwipeProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const cardRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    const checkScreenSize = () => {
      setIsLargeScreen(window.innerWidth >= 768); // 768px'i büyük ekran olarak kabul ediyoruz
    };

    checkScreenSize(); // İlk yükleme kontrolü
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setIsFlipped(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Cevaplandı':
      case 'Onaylandı':
        return '#10B981';
      case 'Reddedildi':
        return '#EF4444';
      case 'Beklemede':
        return '#F59E0B';
      default:
        return '#3B82F6';
    }
  };

  const getStatusShadow = (status) => {
    const color = getStatusColor(status);
    return `0 4px 20px ${color}40, 0 7px 10px -5px ${color}33`;
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };
const handleDelete = (e) => {
  e.stopPropagation();
  if (window.confirm('Bu soruyu silmek istediğinizden emin misiniz?')) {
    onDelete(question.id);
  }
};


  const cardStyle = {
    width: '100%',
    maxWidth: '700px',
    height: '550px',
    perspective: '1000px',
    margin: '0 auto',
    fontFamily: "'Inter', sans-serif",
    cursor: 'pointer',
    minWidth: '280px',
  };

  const cardInnerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    transition: 'transform 0.8s',
    transformStyle: 'preserve-3d',
    transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0)',
  };

  const cardFaceStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    WebkitBackfaceVisibility: 'hidden',
    transform: 'rotateY(0deg)',
    borderRadius: '20px',
    padding: '30px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    boxShadow: getStatusShadow(question.status),
    color: 'white',
    backgroundImage: 'radial-gradient(circle at top right, rgba(255, 255, 255, 0.2) 0%, transparent 70%)',
  };

  const cardBackStyle = {
    ...cardFaceStyle,
    transform: 'rotateY(180deg)',
    overflowY: 'auto',
    backdropFilter: 'blur(30px)',
    background: 'radial-gradient(circle at center, rgba(255, 255, 255, 0.2), transparent)',
  };

  const buttonStyle = {
    padding: '15px 20px',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    fontWeight: '600',
    fontSize: '1rem',
    color: '#E5E7EB',
    backgroundColor: 'rgba(59, 130, 246, 0.8)',
    backdropFilter: 'blur(5px)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
  };

  const buttonStyle2 = {
    padding: '15px 20px',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    fontWeight: '600',
    fontSize: '1rem',
    color: '#E5E7EB',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(35px)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  const statusStyle = {
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '8px 15px',
    borderRadius: '20px',
    fontSize: '0.9rem',
    fontWeight: '600',
    color: '#cdd6eb',
    background: getStatusColor(question.status),
  };

  const progressBarStyle = {
    position: 'absolute',
    bottom: '10px',
    left: '5px',
    width: '98%',
    height: '4px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderBottomLeftRadius: '30px',
    borderBottomRightRadius: '30px',
    overflow: 'hidden',
    opacity: isDragging ? 1 : 0,
    transition: 'opacity 0.3s ease',
  };

  const progressStyle = {
    height: '100%',
    width: `${Math.abs(swipeProgress)}%`,
    backgroundColor: swipeProgress > 0 ? '#10B981' : '#EF4444',
    transition: 'width 0.1s ease-out',
  };

  const switchContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '20px',
    marginBottom: '5px',
  };

  const sliderStyle = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '220px',
    height: '50px',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    padding: '5px',
    marginTop: '10px',
    boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
  };

  const sliderThumbStyle = {
    width: '77px',
    height: '40px',
    borderRadius: '30px',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    transform: isApproved 
      ? 'translateX(55px)' 
      : question.status === 'Reddedildi'
        ? 'translateX(-55px)'
        : 'translateX(0)',
    backgroundColor: getStatusColor(question.status),
    zIndex: 100,
  };

  const sliderTextStyle = {
    position: 'absolute',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    transition: 'opacity 0.3s ease',
  };

  const deleteIconStyle = {
    position: 'absolute',
    top: '20px',
    right: '20px',
    fontSize: '1.2rem',
    color: 'white',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
  };

  return (
    <motion.div 
      style={cardStyle} 
      ref={cardRef}
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      dragElastic={0.2}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={(event, info) => {
        if (Math.abs(info.offset.x) > 100) {
          if (info.offset.x > 0) {
            onStatusChange(question.id, isSpeaker ? 'Cevaplandı' : 'Onaylandı');
            setIsApproved(true);
          } else {
            onStatusChange(question.id, 'Reddedildi');
            setIsApproved(false);
          }
        }
        controls.start({ x: 0 });
        setSwipeProgress(0);
        setIsDragging(false);
      }}
      onDrag={(event, info) => {
        const progress = (info.offset.x / 100) * 100;
        setSwipeProgress(Math.max(-100, Math.min(100, progress)));
      }}
      animate={controls}
      whileDrag={{
        scale: 1.05,
        boxShadow: (_, { point }) => {
          if (point.x > 0) {
            return "0px 0px 20px rgba(16, 185, 129, 0.5)";
          } else if (point.x < 0) {
            return "0px 0px 20px rgba(239, 68, 68, 0.5)";
          }
          return getStatusShadow(question.status);
        },
      }}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <div style={cardInnerStyle}>
        <div style={cardFaceStyle}>
          <div>
            <h3 style={{ margin: '35px 0 15px 0', color: '#E5E7EB', fontSize: '1.5rem' }} className="truncate">
              {question.asker.length > 50 ? question.asker.slice(0, 25) + '...' : question.asker}
            </h3>
            {!question.onlyTakeFloor && (
              <p style={{ fontSize: '1.1rem', color: '#D1D5DB', maxHeight: '300px', overflow: 'auto', lineHeight: '1.6' }}>
                {truncateText(question.text, 500) || 'Soru metni yok'}
              </p>
            )}
            {question.onlyTakeFloor && (
              <p style={{ fontSize: '2rem', color: '#D1D5DB', textAlign: 'center', marginTop: '80px' }}>
                <FaHandPaper /> Söz İstiyor
              </p>
            )}
          </div>
          {question.wantsToAsk && 
            <FaMicrophone style={{ position: 'absolute', bottom: '100px', right: '-30px', fontSize: '18em', color: '#aaa6a638', zIndex: '-1' }} />
          }
          <div style={statusStyle}>{question.status}</div>
          {isLargeScreen && (
            <button 
              style={buttonStyle2}
              onMouseEnter={(e) => {e.target.style.backgroundColor = '#fff'; e.target.style.color = 'black';}} 
              onMouseLeave={(e) => {e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.2)'; e.target.style.color = '#E5E7EB';}}
             onMouseDown={(e) => {e.target.style.backgroundColor = COLORS.BUTTON.PRIMARY.BACKGROUND; e.target.style.color = COLORS.BUTTON.SECONDARY.TEXT;}}
              onClick={(e) => { e.stopPropagation(); openQuestionInNewWindow(question); }}
            >
              Soruyu Görüntüle
            </button>
          )}
          <div style={progressBarStyle}>
            <div style={progressStyle}></div>
          </div>
        </div>
        <div style={cardBackStyle}>
          <h3 style={{ margin: '0 0 15px 0', color: '#E5E7EB', fontSize: '1.5rem' }}>Soru Detayları</h3>
          <FaTrash
            style={deleteIconStyle}
            onClick={handleDelete}
            onMouseOver={(e) => e.currentTarget.style.color = '#DC2626'}
            onMouseOut={(e) => e.currentTarget.style.color = 'white'}
          />
          <p style={{ color: '#D1D5DB', fontSize: '1.1rem' }}><strong>Soru Sahibi:</strong> {question.asker}</p>
          <p style={{ color: '#D1D5DB', fontSize: '1.1rem' }}><strong>Durum:</strong> {question.status}</p>
          {question.speakers && question.speakers.length > 0 && (
            <p style={{ color: '#D1D5DB', fontSize: '1.1rem' }}><strong>Konuşmacı(lar):</strong> {question.speakers.map(s => s.name).join(', ')}</p>
          )}
          {question.wantsToAsk && <p style={{ color: '#D1D5DB', fontSize: '1.1rem' }}>🎤 Soruyu kendisi sormak istiyor</p>}
          <div style={switchContainerStyle}>
            <div style={sliderStyle} onClick={(e) => {
              e.stopPropagation();
              const newStatus = isApproved ? 'Reddedildi' : (isSpeaker ? 'Cevaplandı' : 'Onaylandı');
              setIsApproved(!isApproved);
              onStatusChange(question.id, newStatus);
            }}>
              <span style={{...sliderTextStyle, left: '20px', opacity: question.status === 'Reddedildi' ? 1 : 0.5, color: '#EF4444'}}>Reddet</span>
              <div style={sliderThumbStyle}>
                {question.status === 'Beklemede' ? '?' : question.status === 'Onaylandı' ? '✓' : '✗'}
              </div>
              <span style={{...sliderTextStyle, right: '20px', opacity: isApproved ? 1 : 0.5, color: '#10B981'}}>Onayla</span>
            </div>
          </div>
          <button 
            style={{...buttonStyle, marginTop: '20px', backgroundColor: COLORS.BUTTON.PRIMARY.ACTIVE, color: '#E5E7EB'}}
            
            onMouseEnter={(e) => {e.target.style.backgroundColor = COLORS.BUTTON.PRIMARY.HOVER; e.target.style.color = COLORS.BUTTON.PRIMARY.TEXT;}} 
            onMouseLeave={(e) => {e.target.style.backgroundColor = COLORS.BUTTON.PRIMARY.ACTIVE; e.target.style.color = COLORS.BUTTON.PRIMARY.TEXT;}}
           onMouseDown={(e) => {e.target.style.backgroundColor = COLORS.COLORS.PRIMARY.DARKER; e.target.style.color = COLORS.BUTTON.SECONDARY.TEXT;}}
            onClick={(e) => { e.stopPropagation(); onEdit(question); }}
          >
            Düzenle
          </button>
          <div style={progressBarStyle}>
            <div style={progressStyle}></div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default QuestionCard;
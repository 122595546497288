import api from './api';

const sessionService = {
  getAllSessions: () => api.get('/sessions'),
  // getSessionById: (id) => api.get(`/sessions/${id}`),
  createSession: async (sessionData) => {
    console.log('Sending session data to server:', sessionData);
    try {
      const response = await api.post('/sessions', sessionData);
      console.log('Server response:', response);
      return response.data;
    } catch (error) {
      console.error('Error in createSession:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        throw new Error(`Server error: ${error.response.data.message}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        throw new Error('No response received from server');
      } else {
        console.error('Error setting up request:', error.message);
        throw error;
      }
    }
  },
  updateSession: (id, sessionData) => api.put(`/sessions/${id}`, sessionData),
  deleteSession: (id) => api.delete(`/sessions/${id}`),
   getSessionsByConference: (conferenceId) => api.get(`/sessions/conference/${conferenceId}`),

   getSessionById: async (sessionId) => {
    try {
      const response = await api.get(`/sessions/${sessionId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching session:', error);
      throw error;
    }
  },
};

export default sessionService;
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup, Row, Col } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import { FaCopy, FaWhatsapp } from 'react-icons/fa';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { openQRCodeInNewWindow } from './QRCodeDisplay';

const UpdatedQRModal = ({ show, onHide, participantToken }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const participantLink = `${window.location.origin}/s/${participantToken}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(participantLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleWhatsAppShare = () => {
    const message = `InterEdu.app oturumuna katılıp soru sormak için tıklayın: ${participantLink}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <Logo style={{ width: 250, height: 75 }}/>
        </div>
      </Modal.Header>
      <Modal.Body className="text-center">
        {participantToken ? (
          <>
            <div className="mb-4" style={{display: 'flex', justifyContent: 'center'}}>
              <QRCode value={participantLink} size={256} />
            </div>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                value={participantLink}
                readOnly
              />
              <Button variant="outline-secondary" onClick={handleCopy} style={{ color: '#0097B2' }}>
                <FaCopy /> {copied ? 'Kopyalandı!' : 'Kopyala'}
              </Button>
            </InputGroup>
            {isMobile && (
              <Button variant="success" onClick={handleWhatsAppShare} className="w-100 mb-3" style={{ color: '#0097B2' }}>
                <FaWhatsapp /> WhatsApp ile Paylaş
              </Button>
            )}
          </>
        ) : (
          <p>Katılımcı tokeni henüz oluşturulmamış.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100">
          {isMobile && (
            <Col xs={12} className="mb-2">
              <Button
                variant="primary"
                onClick={() => openQRCodeInNewWindow(participantLink)}
                style={{ color: '#fff', backgroundColor: '#0097B2', borderColor: '#0097B2' }}
                onMouseEnter={(e) => {e.target.style.backgroundColor = '#007B8E'; e.target.style.borderColor = '#007B8E';}}
                onMouseLeave={(e) => {e.target.style.backgroundColor = '#0097B2'; e.target.style.borderColor = '#0097B2';}}
                className="w-100"
              >
                QR Kodu Yeni Sayfada Göster
              </Button>
            </Col>
          )}
          <Col xs={12} md={4} className="text-center text-md-start mb-2 mb-md-0">
            <h4>interEdu.app</h4>
          </Col>
          {!isMobile && (
            <Col md={6}>
              <Button
                variant="primary"
                onClick={() => openQRCodeInNewWindow(participantLink)}
                style={{ color: '#fff', backgroundColor: '#0097B2', borderColor: '#0097B2' }}
                onMouseEnter={(e) => {e.target.style.backgroundColor = '#007B8E'; e.target.style.borderColor = '#007B8E';}}
                onMouseLeave={(e) => {e.target.style.backgroundColor = '#0097B2'; e.target.style.borderColor = '#0097B2';}}
                className="w-100"
              >
                Ayrı Sayfada Göster
              </Button>
            </Col>
          )}
          <Col xs={12} md={2}>
            <Button
              variant="secondary"
              onClick={onHide}
              style={{ color: '#fff' }}
              onMouseEnter={(e) => e.target.style.color = '#000'}
              onMouseLeave={(e) => e.target.style.color = '#fff'}
              className="w-100"
            >
              Kapat
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdatedQRModal;
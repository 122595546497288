import * as XLSX from 'xlsx';

export const exportToExcel = (questions) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(questions.map(q => ({
    'Soru': q.text,
    'Soran': q.asker,
    'Konuşmacı(lar)': Array.isArray(q.speakers)
      ? q.speakers.map(s => s.name || s).join(', ')
      : '',
    'Durum': q.status
  })));

  // Sütun genişlikleri
  ws['!cols'] = [
    { wch: 50 },  // Soru
    { wch: 20 },  // Soran
    { wch: 30 },  // Konuşmacı(lar)
    { wch: 15 }   // Durum
  ];

  // Hücre stilleri
  const headerStyle = {
    fill: { 
      fgColor: { rgb: "E9ECEF" },
    },
    font: { bold: true }
  };

  const lightStyle = {
    fill: {
      fgColor: { rgb: "F2F2F2" },
    }
  };

  const darkStyle = {
    fill: {
      fgColor: { rgb: "E6E6E6" },
    }
  };

  // Başlık satırına stil uygulama
  for (let col = 0; col < 4; col++) {
    const cellAddress = XLSX.utils.encode_cell({ c: col, r: 0 });
    if (!ws[cellAddress]) ws[cellAddress] = {}; // Hücre yoksa oluştur
    ws[cellAddress].s = headerStyle;
  }

  // Satırları gezerek alternatif renkleri uygulama
  for (let i = 1; i <= questions.length; i++) {
    const rowStyle = i % 2 === 0 ? lightStyle : darkStyle;
    for (let col = 0; col < 4; col++) {
      const cellAddress = XLSX.utils.encode_cell({ c: col, r: i });
      if (!ws[cellAddress]) ws[cellAddress] = {}; // Hücre yoksa oluştur
      ws[cellAddress].s = rowStyle;
    }
  }

  XLSX.utils.book_append_sheet(wb, ws, "Sorular");
  XLSX.writeFile(wb, "sorular.xlsx");
};

import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Table, Alert } from 'react-bootstrap';
import { FaCopy } from 'react-icons/fa';
import axios from 'axios';
import tokenService from '../services/tokenService';
import speakerService from '../services/speakerService';

const Settings = ({ show, onHide, sessionId }) => {
  const [tokenExpiration, setTokenExpiration] = useState(1);
  const [autoApproveQuestions, setAutoApproveQuestions] = useState(false);
  const [speakers, setSpeakers] = useState([]);
  const [tokens, setTokens] = useState({ speakers: [], moderators: [] });
  const [error, setError] = useState(null);

  useEffect(() => {
    if (sessionId && show) {
      fetchData();
    }
  }, [sessionId, show]);

  const fetchData = async () => {
    setError(null);
    try {
      await Promise.all([
        fetchSettings(),
        fetchSpeakers(),
        fetchTokens()
      ]);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Veri yüklenirken bir hata oluştu. Lütfen sayfayı yenileyin ve tekrar deneyin.');
    }
  };

  const fetchSettings = async () => {
    try {
      const response = await axios.get(`/api/sessions/${sessionId}`);
      setTokenExpiration(response.data.tokenExpiration || 30);
      setAutoApproveQuestions(response.data.auto_approve_questions || false);
      console.log('Settings:', response.data);
    } catch (error) {
      console.error('Ayarlar yüklenirken hata oluştu:', error);
      throw error;
    }
  };
  const fetchSpeakers = async () => {
    try {
      const response = await speakerService.getSpeakersBySession(sessionId);
      setSpeakers(response.data || []);
      console.log('Speakers:', response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log('No speakers found for this session. This is normal for new sessions.');
        setSpeakers([]);
      } else {
        console.error('Konuşmacılar yüklenirken hata oluştu:', error);
        throw error;
      }
    }
  };
  const fetchTokens = async () => {
    try {
      const response = await tokenService.getTokensForSession(sessionId);
      console.log('Fetched tokens:', response);
      setTokens(response || { speakers: [], moderators: [] });
    } catch (error) {
      console.error('Tokenler yüklenirken hata oluştu:', error);
      setTokens({ speakers: [], moderators: [] });
    }
  };

  const handleSave = async () => {
    try {
      console.log('Sending update request:', { sessionId, tokenExpiration, autoApproveQuestions });
  
      const response = await axios.put(`/api/sessions/${sessionId}`, {
        tokenExpiration,
        auto_approve_questions: autoApproveQuestions
      });
  
      console.log('Server response:', response);
  
      if (response.status === 200) {
        alert('Ayarlar başarıyla güncellendi');
        onHide();
      } else {
        throw new Error('Unexpected response status: ' + response.status);
      }
    } catch (error) {
      console.error('Ayarlar kaydedilirken hata oluştu:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      }
      setError('Ayarlar kaydedilirken bir hata oluştu: ' + (error.response?.data?.message || error.message));
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Link kopyalandı!');
  };

  const getTokenForSpeaker = (speakerId) => {
    console.log('Current tokens:', tokens);
    const speakerToken = tokens.speakers?.find(t => String(t.userId) === String(speakerId))?.token;
    const moderatorToken = tokens.moderators?.find(t => String(t.userId) === String(speakerId))?.token;
    console.log(`Speaker ID: ${speakerId}, Speaker Token: ${speakerToken}, Moderator Token: ${moderatorToken}`);
    return speakerToken || moderatorToken;
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Ayarlar ve Oturum Bilgileri</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form>
          <Form.Group controlId="tokenExpiration">
            <Form.Label>Token Geçerlilik Süresi (Gün)</Form.Label>
            <Form.Control
              type="number"
              value={tokenExpiration}
              onChange={(e) => setTokenExpiration(parseInt(e.target.value))}
              min="1"
            />
          </Form.Group>
          <Form.Group controlId="autoApproveQuestions" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Sorular moderatör onayından geçmeden doğrudan konuşmacı ekranına yönlendirilsin."
              checked={autoApproveQuestions}
              onChange={(e) => setAutoApproveQuestions(e.target.checked)}
            />
          </Form.Group>
        </Form>

        <h5 className="mt-4">Erişim Linkleri</h5>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>İsim</th>
              <th>Rol</th>
              <th>Erişim Linki</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {speakers.map((speaker) => {
              const token = getTokenForSpeaker(speaker.id);
              const link = token ? `${window.location.origin}/session/${token}` : '';
              console.log(`Speaker: ${speaker.name}, ID: ${speaker.id}, Token: ${token}`);
              return (
                <tr key={speaker.id}>
                  <td>{speaker.name}</td>
                  <td>{speaker.role}</td>
                  <td>
                    {link ? (
                      <Form.Control type="text" value={link} readOnly />
                    ) : (
                      <span>Token bulunamadı</span>
                    )}
                  </td>
                  <td>
                    {link && (
                      <>
                        <Button
                          variant="outline-primary"
                          onClick={() => copyToClipboard(link)}
                          className="mr-2"
                        >
                          <FaCopy /> Kopyala
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          İptal
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Kaydet
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Settings;
import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Card,
  Button,
  Form,
  InputGroup,
  Container,
  Row,
  Col,
  Spinner,
  Alert,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
import { useParams } from "react-router-dom";
import useFetchQuestions from "./moderator/hooks/useFetchQuestions";
import useFetchSpeakers from "./moderator/hooks/useFetchSpeakers";
import QuestionCard from "./moderator/components/QuestionCard.tsx";
import EditQuestionModal from "./moderator/components/EditQuestionModal.tsx";
import QuestionTable from "./moderator/components/QuestionTable.tsx";
import { exportToExcel } from "./moderator/utils/exportToExcel";
import { FaDownload, FaQrcode, FaCopy } from "react-icons/fa";
import QRCode from "qrcode.react";
import questionService from "../services/questionService";
import tokenService from "../services/tokenService";
import socketService from "../services/socketService";
import "./styles/moderator.css";
import speakerService from "../services/speakerService";
import sessionService from "../services/sessionService";
import UpdatedQRModal from "./UpdatedQRModal.js";

interface Speaker {
  id: number;
  name: string;
  email: string;
  role: string;
}

interface Question {
  id: number;
  text: string;
  asker: string;
  status: string;
  wantsToAsk: boolean;
  onlyTakeFloor: boolean;
  speakers: Speaker[];
}

const ModeratorPanel: React.FC = () => {
  const [sessionId, setSessionId] = useState<string | null>(null);
  const {
    speakers = [],
    isLoading: speakersLoading,
    error: speakersError,
  } = useFetchSpeakers(sessionId);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [speakerFilter, setSpeakerFilter] = useState<Speaker | null>(null);
  const [participantToken, setParticipantToken] = useState<string | null>(null);
  const [socketError, setSocketError] = useState<string | null>(null);
  const [showQrModal, setShowQrModal] = useState(false);
  const { token } = useParams<{ token: string }>();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [editingQuestion, setEditingQuestion] = useState<Question | null>(null);
  const [viewMode, setViewMode] = useState("card");
  const [moderatorInfo, setModeratorInfo] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [sessionName, setSessionName] = useState<string>("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchModeratorInfoAndQuestions = async () => {
      if (!token) {
        setError("Token bulunamadı.");
        return;
      }

      try {
        setIsLoading(true);
        const tokenInfo = await tokenService.validateToken(token);
        if (!tokenInfo.valid || tokenInfo.role !== "moderator") {
          setError("Geçersiz veya yetkisiz token.");
          return;
        }

        const speakerData = await speakerService.getSpeakerInfoById(
          tokenInfo.userId
        );
        setModeratorInfo(speakerData);

        setSessionId(tokenInfo.sessionId);

        const sessionData = await sessionService.getSessionById(
          tokenInfo.sessionId
        );
        setSessionName(sessionData.name);

        const fetchedQuestions = await questionService.getQuestionsBySession(
          tokenInfo.sessionId
        );
        setQuestions(fetchedQuestions);

        const pToken = await tokenService.getParticipantToken(
          tokenInfo.sessionId
        );
        setParticipantToken(pToken);

        const socket = socketService.connect(tokenInfo.sessionId);
        setIsSocketConnected(true);

        socket.on("newQuestion", (newQuestion) => {
          setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
        });

        socket.on("updateQuestion", (updatedQuestion) => {
          setQuestions((prevQuestions) =>
            prevQuestions.map((q) =>
              q.id === updatedQuestion.id ? updatedQuestion : q
            )
          );
        });

        return () => {
          socket.disconnect();
        };
      } catch (error) {
        console.error("Bilgi alma hatası:", error);
        setError("Oturum bilgileri yüklenirken bir hata oluştu.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchModeratorInfoAndQuestions();
  }, [token]);

  const handleStatusChange = useCallback(
    async (questionId: number, newStatus: string) => {
      try {
        const updatedQuestion = await questionService.updateQuestionStatus(
          questionId,
          newStatus
        );
        setQuestions((prevQuestions) =>
          prevQuestions.map((q) =>
            q.id === questionId ? { ...q, status: newStatus } : q
          )
        );
      } catch (error) {
        console.error("Soru durumu güncellenirken hata oluştu:", error);
      }
    },
    [setQuestions]
  );

  const handleDeleteQuestion = useCallback(async (questionId: number) => {
    try {
      await questionService.deleteQuestion(questionId);
      setQuestions((prevQuestions) => prevQuestions.filter((q) => q.id !== questionId));
    } catch (error) {
      console.error("Soru silinirken hata oluştu:", error);
      setError("Soru silinirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  }, []);

  const handleEditQuestion = useCallback((question: Question) => {
    setEditingQuestion(question);
  }, []);

  const statusMapping = {
    Beklemede: "Pending",
    Onaylandı: "Approved",
    Reddedildi: "Rejected",
  };

  const handleSaveEdit = useCallback(
    async (editedQuestion: Question) => {
      try {
        const response = await questionService.updateQuestion(
          editedQuestion.id,
          {
            session_id: sessionId,
            question_text: editedQuestion.text,
            participant_name: editedQuestion.asker,
            status:
              statusMapping[editedQuestion.status] || editedQuestion.status,
            speakers: editedQuestion.speakers.map((s) => s.id),
          }
        );
        if (response.status === 200) {
          setQuestions((prevQuestions) =>
            prevQuestions.map((q) =>
              q.id === editedQuestion.id ? editedQuestion : q
            )
          );
          setEditingQuestion(null);
        } else {
          throw new Error("Failed to update question");
        }
      } catch (error) {
        console.error("Error updating question:", error);
        alert("Soru güncellenirken bir hata oluştu. Lütfen tekrar deneyin.");
      }
    },
    [sessionId, setQuestions]
  );

  const filteredQuestions = useMemo(() => {
    if (!questions) return [];
    return questions.filter((question) => {
      const questionText = question.text || "";
      const speakerMatch =
        speakerFilter === null ||
        speakerFilter.value === "all" ||
        (question.speakers &&
          question.speakers.some(
            (s) => s.id.toString() === speakerFilter.value
          ));

      return (
        questionText.toLowerCase().includes(searchTerm.toLowerCase()) &&
        speakerMatch &&
        (statusFilter === "all" || question.status === statusFilter)
      );
    });
  }, [questions, searchTerm, speakerFilter, statusFilter]);

  const handleDownloadExcel = () => {
    exportToExcel(filteredQuestions);
  };

  const speakerOptions = useMemo(() => {
    return [
      { value: "all", label: "Tüm Konuşmacılar" },
      ...speakers.map((speaker) => ({
        value: speaker.id.toString(),
        label: speaker.name,
      })),
    ];
  }, [speakers]);

  const participantLink = `${window.location.origin}/s/${participantToken}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(participantLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  if (isLoading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="m-3">
        {error}
      </Alert>
    );
  }

  return (
    <Container
      fluid
      className="speaker-panel justify-content-center min-vh-100"
    >
      <Row>
        {!isSocketConnected && (
          <Alert variant="warning">
            Socket.IO bağlantısı kurulamadı. Gerçek zamanlı güncellemeler
            alınamayabilir.
          </Alert>
        )}
        <Col>
          <Card className="admin-panel conference-card">
            <Card.Header className="p-4">
              <Row className="align-items-center">
                <Col md={4} className="text-center text-md-start mb-3 mb-md-0">
                  <h2 className="font-weight-bold mb-0">
                    {moderatorInfo ? moderatorInfo.name : "Yükleniyor..."}
                  </h2>
                  <h6>(Moderatör)</h6>
                </Col>
                <Col md={4} className="text-center mb-3 mb-md-0">
                  <h3>{sessionName}</h3>
                </Col>
                <Col
                  md={4}
                  className="d-flex justify-content-center justify-content-md-end"
                >
                  <div className="view-switch ms-2">
                    <button
                      className={viewMode === "table" ? "active" : ""}
                      onClick={() => setViewMode("table")}
                    >
                      Tablo
                    </button>
                    <button
                      className={viewMode === "card" ? "active" : ""}
                      onClick={() => setViewMode("card")}
                    >
                      Kart
                    </button>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              {questions.length === 0 ? (
                <div className="text-center">
                  <h4 className="mb-4">Henüz soru bulunmamaktadır.</h4>
                  <div
                    className="d-flex justify-content-center mb-4" 
                    onClick={() => setShowQrModal(true)}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="d-block d-sm-none">
                      <QRCode value={participantLink} size={200} />{" "}
                      {/* Small screen */}
                    </div>
                    <div className="d-none d-sm-block d-md-none">
                      <QRCode value={participantLink} size={300} />{" "}
                      {/* Medium screen */}
                    </div>
                    <div className="d-none d-md-block">
                      <QRCode value={participantLink} size={400} />{" "}
                      {/* Large screen */}
                    </div>
                  </div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      value={participantLink}
                      readOnly
                    />
                    <Button variant="outline-secondary" onClick={handleCopy}>
                      <FaCopy /> {copied ? "Kopyalandı!" : "Kopyala"}
                    </Button>
                  </InputGroup>
                  <p className="mt-3">
                    Oturum kodunu paylaşarak soru almaya başlayabilirsiniz.
                  </p>
                </div>
              ) : (
                <>
                  <Row className="mb-3 filter-select__control">
                    <Col md={4} style={{ marginBottom: "10px" }}>
                      <InputGroup>
                        <Form.Control
                          placeholder="Soru ara..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </InputGroup>
                    </Col>
                    <Col md={4} style={{ marginBottom: "10px" }}>
                      <Select
                        options={speakerOptions}
                        value={speakerFilter}
                        onChange={setSpeakerFilter}
                        placeholder="Konuşmacı Filtrele"
                      />
                    </Col>
                    <Col md={4} style={{ marginBottom: "10px" }}>
                      <Form.Control
                        as="select"
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                      >
                        <option value="all">Tüm Durumlar</option>
                        <option value="Beklemede">Beklemede</option>
                        <option value="Onaylandı">Onaylandı</option>
                        <option value="Reddedildi">Reddedildi</option>
                      </Form.Control>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end mb-3">
                    <Button
                      variant="outline-secondary"
                      onClick={() => setShowQrModal(true)}
                      className="me-2"
                      disabled={!participantToken}
                    >
                      <FaQrcode /> QR Kodu Göster
                    </Button>
                    <Button
                      variant="outline-info"
                      onClick={handleDownloadExcel}
                    >
                      <FaDownload /> Excel'e Aktar
                    </Button>
                  </div>
                  {viewMode === "table" ? (
                    <QuestionTable
                      questions={filteredQuestions}
                      onStatusChange={handleStatusChange}
                      onEdit={handleEditQuestion}
                      isSpeaker={false}
                    />
                  ) : (
                    <Row  xs={1} sm={1} md={2} xl={3} className="g-4">
                      {filteredQuestions.map((question) => (
                        <Col key={question.id} md={6} lg={4}>
                          <QuestionCard
                            question={question}
                            onStatusChange={handleStatusChange}
                            onEdit={handleEditQuestion}
                            onDelete={handleDeleteQuestion}
                            isSpeaker={false}
                          />
                        </Col>
                      ))}
                    </Row>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {editingQuestion && (
        <EditQuestionModal
          show={!!editingQuestion}
          onHide={() => setEditingQuestion(null)}
          question={editingQuestion}
          onSave={handleSaveEdit}
          speakers={speakers}
          sessionId={sessionId}
          isSpeakerPanel={false}
        />
      )}

      <UpdatedQRModal
        show={showQrModal}
        onHide={() => setShowQrModal(false)}
        participantToken={participantToken}
      />
    </Container>
  );
};

export default ModeratorPanel;
